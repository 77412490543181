.header .navbar-nav, .header .navbar-nav li, .header .navbar-nav  h6 {
    color: white;
}
.header .navbar-nav li {
    margin-right: 15px;
    /* margin-left: 15px;
    padding-right: 15px;
    padding-left: 15px; */
}
.hidden {
    display:none;
}