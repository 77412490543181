@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&amp;family=Nunito+Sans:wght@400;600;700&amp;display=swap);
:root {
    --bs-sidebar-bg: #ffffff;
    --bs-sidebar-border-color: #e9ebef;
    --bs-sidebar-menu-item-color: #7b8190;
    --bs-sidebar-menu-sub-item-color: #7b8190;
    --bs-sidebar-menu-item-icon-color: #7b8190;
    --bs-sidebar-menu-item-hover-color: #383c40;
    --bs-sidebar-menu-item-active-color: #5466f9;
    --bs-sidebar-menu-sub-item-active-color: #383c40;
    --bs-sidebar-menu-item-active-bg-color: #eeedf9;
    --bs-sidebar-dark-bg: #24243c;
    --bs-sidebar-dark-menu-item-color: #9292a9;
    --bs-sidebar-dark-menu-sub-item-color: #9292a9;
    --bs-sidebar-dark-menu-item-icon-color: #9292a9;
    --bs-sidebar-dark-menu-item-hover-color: #d8d8df;
    --bs-sidebar-dark-menu-item-active-color: #d8d8df;
    --bs-sidebar-dark-menu-item-active-bg-color: #2c2c45;
    --bs-header-bg: #ffffff;
    --bs-header-item-color: #555b6d;
    --bs-header-dark-bg:#000c66; /* #5466f9 */; /*radial-gradient(at 50% -20%, #3f1e59, #311748) fixed;*/
    --bs-header-dark-item-color: #e9ecef;
    --bs-topbar-search-bg: #f8f7fc;
    --bs-footer-bg: #ffffff;
    --bs-footer-color: #74788d;
    --bs-topnav-bg: #fff;
    --bs-menu-item-color: #7b8190;
    --bs-menu-item-active-color: #5466f9;
    --bs-topnav-dark-bg: #03314D;
    --bs-menu-dark-item-color: rgba(255, 255, 255, 0.5);
    --bs-menu-item-active-border-color: #5466f9;
    --bs-menu-dark-item-hover-color: #ffffff;
    --bs-menu-dark-item-active-color: #fff;
    --bs-navbar-brand-box-bg: #ffffff;
    --bs-boxed-body-bg: #e9e5eb;
    --bs-apex-grid-color: #f8f9fa;
    --bs-body-bg: #f9f6fa;
    --bs-body-color: #1a1823;
    --bs-body-color-rgb: 26, 24, 35;
    --bs-display-none: none;
    --bs-display-block: block;
    --bs-light: #f8f7fc;
    --bs-light-rgb: 248, 247, 252;
    --bs-dark: #212529;
    --bs-dark-rgb: 33, 37, 41;
    --bs-text-muted: #959396;
    --bs-link-color: #5466f9;
    --bs-link-hover-color: #5466f9;
    --bs-border-color: #eff0f2;
    --bs-dropdown-bg: #fff;
    --bs-dropdown-link-color: #212529;
    --bs-dropdown-link-hover-color: #1f2327;
    --bs-dropdown-link-hover-bg: #f8f9fa;
    --bs-dropdown-border-width: 0px;
    --bs-card-bg: #fff;
    --bs-card-cap-bg: #fff;
    --bs-card-border-color: #eff0f2;
    --bs-card-logo-dark: block;
    --bs-card-logo-light: none;
    --bs-modal-bg: #fff;
    --bs-modal-content-bg: #fff;
    --bs-modal-content-border-color: #eff0f2;
    --bs-nav-tabs-link-active-color: #1a1823;
    --bs-nav-tabs-link-active-bg: #f9f6fa;
    --bs-accordion-button-active-color: #6b5fba;
    --bs-accordion-bg: #fff;
    --bs-accordion-button-bg: #f4f5f6;
    --bs-table-color: #1a1823;
    --bs-progress-bg: #f4f5f6;
    --bs-toast-background-color: rgba(255, 255, 255, 0.85);
    --bs-toast-border-color: rgba(0, 0, 0, 0.1);
    --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
    --bs-list-group-hover-bg: #f8f9fa;
    --bs-popover-bg: #fff;
    --bs-pagination-hover-bg: #f4f5f6;
    --bs-input-bg: #fff;
    --bs-input-group-addon-border-color: #e2e5e8;
    --bs-input-border: #e2e5e8;
    --bs-input-border-color: #e2e5e8;
    --bs-input-focus-border: #bbb5e7;
    --bs-input-disabled-bg: #f4f5f6;
    --bs-table-head-bg: #f8f9fa;
    --bs-input-placeholder-color: #959396;
    --bs-input-group-addon-bg: #f4f5f6;
    --bs-input-check-border: var(--bs-input-border)
}

[data-layout-mode=dark] {
    --bs-gray-100: #212529;
    --bs-gray-200: #2a2a42;
    --bs-gray-300: #2d2d49;
    --bs-gray-400: #9295a4;
    --bs-gray-500: #858592;
    --bs-gray-600: #c3cbe4;
    --bs-gray-700: #d8d8df;
    --bs-gray-800: #eff2f7;
    --bs-gray-900: #f8f9fa;
    --bs-sidebar-bg: #ffffff;
    --bs-sidebar-border-color: #e9ebef;
    --bs-sidebar-menu-item-color: #7b8190;
    --bs-sidebar-menu-sub-item-color: #7b8190;
    --bs-sidebar-menu-item-icon-color: #7b8190;
    --bs-sidebar-menu-item-hover-color: #383c40;
    --bs-sidebar-menu-item-active-color: #5466f9;
    --bs-sidebar-menu-sub-item-active-color: #383c40;
    --bs-sidebar-menu-item-active-bg-color: #eeedf9;
    --bs-sidebar-dark-bg: #24243c;
    --bs-sidebar-dark-menu-item-color: #9292a9;
    --bs-sidebar-dark-menu-sub-item-color: #9292a9;
    --bs-sidebar-dark-menu-item-icon-color: #9292a9;
    --bs-sidebar-dark-menu-item-hover-color: #d8d8df;
    --bs-sidebar-dark-menu-item-active-color: #d8d8df;
    --bs-sidebar-dark-menu-item-active-bg-color: #2c2c45;
    --bs-header-bg: #ffffff;
    --bs-header-item-color: #555b6d;
    --bs-header-dark-bg: radial-gradient(at 50% -20%, #24243c, #222236) fixed;
    --bs-header-dark-item-color: #e9ecef;
    --bs-topbar-search-bg: #f8f7fc;
    --bs-footer-bg: #24243c;
    --bs-footer-color: #d8d8df;
    --bs-menu-item-color: #9292a3;
    --bs-menu-item-active-color: #5466f9;
    --bs-topnav-dark-bg: #03314D;
    --bs-menu-dark-item-color: rgba(255, 255, 255, 0.5);
    --bs-menu-item-active-border-color: #5466f9;
    --bs-menu-dark-item-hover-color: #ffffff;
    --bs-menu-dark-item-active-color: #fff;
    --bs-navbar-brand-box-bg: #ffffff;
    --bs-apex-grid-color: #32394e;
    --bs-body-bg: #1b1c31;
    --bs-body-color: #9295a4;
    --bs-body-color-rgb: 26, 24, 35;
    --bs-display-none: block;
    --bs-display-block: none;
    --bs-card-title-desc: #9295a4;
    --bs-topnav-bg: #2e2e48;
    --bs-topnav-item-color: #9295a4;
    --bs-topnav-item-color-active: #fff;
    --bs-light: #2d2d49;
    --bs-light-rgb: 45, 45, 73;
    --bs-dark: #eff2f7;
    --bs-dark-rgb: 239, 242, 247;
    --bs-text-muted: #858592;
    --bs-link-color: #d8d8df;
    --bs-link-hover-color: #d8d8df;
    --bs-border-color: #2d2d49;
    --bs-dropdown-bg: #2a2a42;
    --bs-dropdown-link-color: #858592;
    --bs-dropdown-link-hover-color: #ecedee;
    --bs-dropdown-link-hover-bg: #2d2d49;
    --bs-dropdown-border-width: 1px;
    --bs-card-bg: #24243c;
    --bs-card-cap-bg: #24243c;
    --bs-card-border-color: #2b2b46;
    --bs-card-logo-dark: none;
    --bs-card-logo-light: block;
    --bs-modal-bg: #2a2a42;
    --bs-modal-content-bg: #2a2a42;
    --bs-modal-content-border-color: #2d2d49;
    --bs-nav-tabs-link-active-color: #f8f9fa;
    --bs-nav-tabs-link-active-bg: #2d2d49;
    --bs-accordion-button-active-color: #fff;
    --bs-accordion-bg: transparent;
    --bs-accordion-button-bg: #2d2d49;
    --bs-table-color: #9295a4;
    --bs-boxed-body-bg: #14142b;
    --bs-progress-bg: #2d2d49;
    --bs-toast-background-color: rgba(45, 45, 73, 0.85);
    --bs-toast-border-color: rgba(255, 255, 255, 0.1);
    --bs-toast-header-border-color: rgba(255, 255, 255, 0.05);
    --bs-list-group-hover-bg: #2f2f4a;
    --bs-popover-bg: #2c2c45;
    --bs-pagination-hover-bg: #32324e;
    --bs-input-bg: #2e2e48;
    --bs-input-group-addon-border-color: #2d2d49;
    --bs-input-border: #363654;
    --bs-input-border-color: #2d2d49;
    --bs-input-focus-border: #353556;
    --bs-input-disabled-bg: #2a2a42;
    --bs-table-head-bg: #2d2d49;
    --bs-input-placeholder-color: #9295a4;
    --bs-input-group-addon-bg: #363652;
    --bs-input-check-border: #353556
}

#page-topbar {
    position: sticky;
    top: 0;
    right: 0;
    margin-left: 255px;
    z-index: 1002;
    background-color: var(--bs-header-bg);
    -webkit-box-shadow: 0 2px 3px rgba(52, 58, 64, .06);
    box-shadow: 0 2px 3px rgba(52, 58, 64, .06)
}

.navbar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    height: 85px;
    padding: 0 calc(22px / 2) 0 0
}

.navbar-header .dropdown .header-item.show {
    color: #5466f9
}

.navbar-header .navbar-brand-box {
    display: none
}

.verti-dash-content {
    background: var(--bs-header-dark-bg);
    border-top: 1px solid rgba(255, 255, 255, .1);
    padding: 0 calc(22px / 2) 0 calc(22px / 2)
}

body:not([data-sidebar-size=sm]) .navbar-header .vertical-menu-btn {
    display: none
}

body:not([data-sidebar-size=sm]) .app-search {
    margin-left: 22px
}

.navbar-brand-box {
    padding: 0 1.5rem;
    width: 255px;
    position: fixed;
    z-index: 1;
    background-color: var(--bs-navbar-brand-box-bg)
}

.logo {
    line-height: 85px
}

.logo .logo-sm {
    display: none
}

.logo-dark {
    display: block
}

.logo-light {
    display: none
}

.logo-txt {
    font-weight: 700;
    font-size: 20px;
    vertical-align: text-bottom;
    margin-left: 5px;
    line-height: 1;
    color: #343a40 !important
}

.app-search {
    padding: calc(47px / 2) 0
}

.app-search .form-control {
    height: 40px;
    padding-left: 40px;
    padding-right: 20px;
    border-color: #eff0f2;
    background-color: var(--bs-topbar-search-bg);
    -webkit-box-shadow: none;
    box-shadow: none
}

.app-search span {
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 38px;
    left: 13px;
    top: 0;
    color: #959396
}

.megamenu-list li {
    position: relative;
    padding: 5px 0
}

.megamenu-list li a {
    color: #1a1823
}

@media (max-width:991.98px) {
    .navbar-brand-box {
        width: auto
    }
    #page-topbar {
        margin-left: 0
    }
    #page-topbar .navbar-header .navbar-brand-box {
        display: inline-block;
        position: relative;
        background: var(--bs-header-dark-bg)
    }
    #page-topbar .navbar-header .vertical-menu-btn {
        display: inline-block
    }
    .logo span.logo-lg {
        display: none
    }
    .logo span.logo-sm {
        display: inline-block
    }
}

.page-content {
    padding: calc(85px - 33px) calc(22px / 2) 60px calc(22px / 2)
}

.header-item {
    height: 85px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: var(--bs-header-item-color);
    border: 0;
    border-radius: 0
}

.header-item:hover {
    color: var(--bs-header-item-color)
}

.header-profile-user {
    height: 36px;
    width: 36px;
    border: 1px solid #e9ebed;
    padding: 3px
}

.user .user-item-desc {
    line-height: 1.2
}

.user .user-name {
    font-size: 14.4px;
    display: block;
    color: #1a1823
}

.user .user-sub-title {
    color: var(--bs-text-muted);
    font-size: 11px;
    font-weight: 600
}

.noti-icon {
    position: relative
}

.noti-icon i {
    font-size: 20px;
    color: var(--bs-header-item-color);
    margin-top: 6px
}

.noti-icon .noti-dot {
    position: absolute;
    top: 26px;
    right: 8px;
    height: 15px;
    width: 15px;
    color: #fff;
    line-height: 13px;
    font-weight: 500;
    font-size: 10px
}

.notification-item .d-flex {
    padding: .75rem 1rem
}

.notification-item .d-flex:hover {
    background-color: var(--bs-dropdown-link-hover-bg)
}

.dropdown-icon-item {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    display: block;
    border: 1px solid transparent;
    color: #959396
}

.dropdown-icon-item img {
    height: 24px
}

.dropdown-icon-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.dropdown-icon-item:hover {
    border-color: #f4f5f6
}

.fullscreen-enable [data-toggle=fullscreen] .uil-minus-path::before {
    content: "\eb8d"
}

body[data-topbar=dark] #page-topbar {
    background: var(--bs-header-dark-bg)
}

body[data-topbar=dark] .navbar-header .dropdown.show .header-item {
    background-color: rgba(255, 255, 255, .05)
}

body[data-topbar=dark] .header-profile-user {
    border-color: rgba(255, 255, 255, .25)
}

body[data-topbar=dark] .user-name {
    color: var(--bs-header-dark-item-color)
}

body[data-topbar=dark] .user-sub-title {
    color: rgba(255, 255, 255, .5)
}

body[data-topbar=dark] .noti-icon i {
    color: var(--bs-header-dark-item-color)
}

body[data-topbar=dark] .noti-icon .noti-dot {
    -webkit-box-shadow: 0 0 0 3px var(--bs-header-dark-bg);
    box-shadow: 0 0 0 3px var(--bs-header-dark-bg)
}

body[data-topbar=dark] .app-search .form-control {
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .15);
    color: rgba(255, 255, 255, .7)
}

body[data-topbar=dark] .app-search input.form-control::-webkit-input-placeholder,
body[data-topbar=dark] .app-search span {
    color: rgba(255, 255, 255, .5)
}

body[data-sidebar=dark] .navbar-brand-box {
    background: var(--bs-sidebar-dark-bg)
}

body[data-sidebar=dark] .navbar-brand-box .logo-dark {
    display: none
}

body[data-sidebar=dark] .navbar-brand-box .logo-light {
    display: block
}

@media (max-width:600px) {
    .navbar-header .dropdown {
        position: static
    }
    .navbar-header .dropdown .dropdown-menu {
        left: 10px !important;
        right: 10px !important
    }
}

@media (max-width:380px) {
    .navbar-brand-box {
        display: none
    }
}

@media (max-width:374.99px) {
    .navbar-brand-box {
        padding: 0 1rem
    }
    .language-switch {
        display: none !important
    }
}

.page-title-box {
    padding: calc(22px + 12px) 0 22px 0
}

.page-title-box .breadcrumb {
    background-color: transparent;
    padding: 0
}

.page-title-box .breadcrumb .breadcrumb-item a {
    color: rgba(255, 255, 255, .5)
}

.page-title-box .breadcrumb .breadcrumb-item.active {
    color: #fff
}

.page-title-box h4 {
    font-size: 18px !important;
    color: #fff
}

@media (max-width:575.98px) {
    .page-title-box .page-title-right {
        display: none
    }
}

.footer {
    bottom: 0;
    padding: 20px calc(22px / 2);
    position: absolute;
    right: 0;
    color: var(--bs-footer-color);
    left: 250px;
    background-color: var(--bs-footer-bg);
    height: 60px;
    -webkit-box-shadow: 0 0 4px rgba(15, 34, 58, .12);
    box-shadow: 0 0 4px rgba(15, 34, 58, .12)
}

@media (max-width:992px) {
    .footer {
        left: 0
    }
}

body[data-sidebar-size=sm] .footer {
    left: 70px
}

body[data-layout=horizontal] .footer {
    left: 0 !important
}

.right-bar {
    background-color: var(--bs-card-bg);
    -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
    display: block;
    position: fixed;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    width: 280px;
    z-index: 9999;
    float: right !important;
    right: -290px;
    top: 0;
    bottom: 0
}

.right-bar .right-bar-toggle-close {
    background-color: #444c54;
    height: 24px;
    width: 24px;
    line-height: 24px;
    color: #f4f5f6;
    text-align: center;
    border-radius: 50%
}

.right-bar .right-bar-toggle-close:hover {
    background-color: #4b545c
}

.rightbar-overlay {
    background-color: rgba(52, 58, 64, .55);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 9998;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out
}

.right-bar-enabled .right-bar {
    right: 0
}

.right-bar-enabled .rightbar-overlay {
    display: block
}

@media (max-width:575.98px) {
    .right-bar {
        overflow: auto
    }
    .right-bar .slimscroll-menu {
        height: auto !important
    }
}

.metismenu {
    margin: 0
}

.metismenu li {
    display: block;
    width: 100%
}

.metismenu .mm-collapse {
    display: none
}

.metismenu .mm-collapse:not(.mm-show) {
    display: none
}

.metismenu .mm-collapse.mm-show {
    display: block
}

.metismenu .mm-collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: .35s;
    transition-duration: .35s;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility
}

.vertical-menu {
    width: 255px;
    z-index: 1005;
    background: var(--bs-sidebar-bg);
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    border-right: 1px solid var(--bs-sidebar-border-color);
    -webkit-box-shadow: 0 2px 3px rgba(52, 58, 64, .06);
    box-shadow: 0 2px 3px rgba(52, 58, 64, .06)
}

.vertical-menu .vertical-menu-btn {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2
}

.main-content {
    margin-left: 255px;
    overflow: hidden
}

.main-content .content {
    padding: 0 15px 10px 15px;
    margin-top: 85px
}

.sidebar-menu-scroll {
    height: calc(100% - 85px);
    margin-top: 85px
}

#sidebar-menu {
    padding: 0 0 30px 0
}

#sidebar-menu .mm-active>.has-arrow:after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

#sidebar-menu .has-arrow:after {
    content: "\f0140";
    font-family: "Material Design Icons";
    display: block;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-size: 1rem;
    position: absolute;
    right: 15px;
    top: 9px
}

#sidebar-menu .has-arrow+ul .has-arrow:after {
    right: 25px;
    top: 7px
}

#sidebar-menu .metismenu .menu-title::after {
    display: none
}

#sidebar-menu .metismenu .menu-item {
    margin-left: 3px
}

#sidebar-menu ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: .65rem 1rem;
    color: var(--bs-sidebar-menu-item-color);
    position: relative;
    font-weight: 400;
    -webkit-transition: all .4s;
    transition: all .4s;
    margin: 0 10px;
    border-radius: 3px;
    font-size: 14.4px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

#sidebar-menu ul li a .nav-icon {
    display: inline-block;
    min-width: 1.75rem;
    vertical-align: middle;
    color: var(--bs-sidebar-menu-item-icon-color);
    -webkit-transition: all .3s;
    transition: all .3s;
    top: -1px;
    font-size: 18px
}

#sidebar-menu ul li a:hover {
    color: var(--bs-sidebar-menu-item-hover-color)
}

#sidebar-menu ul li a:hover .nav-icon {
    color: var(--bs-sidebar-menu-item-hover-color)
}

#sidebar-menu ul li .badge {
    margin-left: auto
}

#sidebar-menu ul li ul.sub-menu {
    padding: 0
}

#sidebar-menu ul li ul.sub-menu li a {
    padding: .5rem 1.5rem .5rem 3.5rem;
    font-weight: 400;
    color: var(--bs-sidebar-menu-sub-item-color);
    margin: 0;
    background-color: transparent;
    font-size: 14px;
    font-family: "Noto Sans", sans-serif;
    margin-right: 10px
}

#sidebar-menu ul li ul.sub-menu li a:hover {
    color: var(--bs-sidebar-menu-item-hover-color)
}

#sidebar-menu ul li ul.sub-menu li a:hover:before {
    border-color: #5466f9;
    background-color: #5466f9
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu {
    padding: 0
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
    padding: .4rem 1.5rem .4rem 4.5rem
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a:before {
    left: 55px;
    top: 15px
}

#sidebar-menu ul li.mm-active {
    color: var(--bs-sidebar-menu-item-active-color)
}

#sidebar-menu ul li.mm-active>a {
    background-color: var(--bs-sidebar-menu-item-active-bg-color);
    color: var(--bs-sidebar-menu-item-active-color)
}

#sidebar-menu ul li.mm-active>a:before {
    content: "";
    height: 100%;
    top: 0;
    width: 3px;
    background-color: #5466f9;
    position: absolute;
    left: 0;
    border-end-start-radius: 3px;
    border-start-start-radius: 3px
}

#sidebar-menu ul li.mm-active>a .nav-icon {
    color: var(--bs-sidebar-menu-item-active-color) !important
}

#sidebar-menu ul li.mm-active .mm-show .mm-active>a {
    color: var(--bs-sidebar-menu-item-active-color) !important
}

#sidebar-menu ul li.mm-active .mm-show .mm-active>a:before {
    display: none
}

.menu-title {
    padding: 12px 25px !important;
    letter-spacing: .08em;
    pointer-events: none;
    cursor: default;
    font-size: 10px;
    text-transform: uppercase;
    color: var(--bs-sidebar-menu-item-icon-color);
    font-weight: 600;
    opacity: .6
}

@media (max-width:992px) {
    .vertical-menu {
        display: none;
        top: 85px
    }
    .vertical-menu .sidebar-menu-scroll {
        height: 100%;
        margin-top: 0
    }
    .vertical-menu .navbar-brand-box,
    .vertical-menu .vertical-menu-btn {
        display: none
    }
    .main-content {
        margin-left: 0 !important
    }
    body.sidebar-enable .vertical-menu {
        display: block
    }
}

@media (min-width:769px) {
    body[data-sidebar-size=sm] {
        min-height: 1850px
    }
}

body[data-sidebar-size=sm] .main-content {
    margin-left: 70px
}

body[data-sidebar-size=sm] .navbar-brand-box {
    width: 70px !important
}

body[data-sidebar-size=sm] .logo span.logo-lg {
    display: none
}

body[data-sidebar-size=sm] .logo span.logo-sm {
    display: block
}

body[data-sidebar-size=sm] #page-topbar {
    margin-left: 70px
}

body[data-sidebar-size=sm] .vertical-menu {
    position: absolute;
    width: 70px !important;
    z-index: 1002
}

body[data-sidebar-size=sm] .vertical-menu .simplebar-content-wrapper,
body[data-sidebar-size=sm] .vertical-menu .simplebar-mask {
    overflow: visible !important
}

body[data-sidebar-size=sm] .vertical-menu .simplebar-scrollbar,
body[data-sidebar-size=sm] .vertical-menu .vertical-menu-btn {
    display: none !important
}

body[data-sidebar-size=sm] .vertical-menu .simplebar-offset {
    bottom: 0 !important
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu .badge,
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu .menu-title {
    display: none !important
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu .nav.collapse {
    height: inherit !important
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu .has-arrow:after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li {
    position: relative;
    white-space: nowrap
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li>a {
    padding: 15px 0;
    -webkit-transition: none;
    transition: none;
    display: block
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li>a:before {
    display: none
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li>a:active,
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li>a:focus,
body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li>a:hover {
    color: var(--bs-sidebar-menu-item-hover-color)
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li>a i {
    font-size: 1.3rem;
    text-align: center;
    min-width: 50px;
    padding-bottom: 0
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li>a span {
    display: none;
    padding-left: 25px
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li>a.has-arrow:after {
    display: none
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li>a .nav-icon {
    top: -2px
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li:hover>a {
    position: relative;
    width: calc(180px + 70px);
    color: #5466f9;
    background-color: var(--bs-sidebar-menu-item-active-bg-color);
    -webkit-transition: none;
    transition: none;
    -webkit-box-shadow: inset 0 3px 10px 0 rgba(154, 161, 171, .2);
    box-shadow: inset 0 3px 10px 0 rgba(154, 161, 171, .2)
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li:hover>a .nav-icon {
    color: #5466f9
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li:hover>a span {
    display: inline
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li:hover>ul {
    display: block;
    left: 70px;
    position: absolute;
    width: 190px;
    height: auto !important;
    -webkit-box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);
    box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1)
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li:hover>ul ul {
    -webkit-box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1);
    box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, .1)
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li:hover>ul a {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 8px 20px;
    position: relative;
    width: 190px;
    z-index: 6;
    color: var(--bs-sidebar-menu-sub-item-color)
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li:hover>ul a:hover {
    color: var(--bs-sidebar-menu-item-hover-color)
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul ul {
    padding: 5px 0;
    z-index: 9999;
    display: none;
    background-color: var(--bs-sidebar-bg)
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul ul li:hover>ul {
    display: block;
    left: 190px;
    height: auto !important;
    margin-top: -36px;
    position: absolute;
    width: 190px
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul ul li>a span.pull-right {
    position: absolute;
    right: 20px;
    top: 12px;
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul ul li.active a {
    color: #f8f9fa
}

body[data-sidebar-size=sm] .vertical-menu #sidebar-menu ul li ul.sub-menu li a:before {
    display: none
}

body[data-sidebar=dark] .logo-txt {
    color: #fff !important
}

body[data-sidebar=dark] .vertical-menu {
    background: var(--bs-sidebar-dark-bg);
    border-color: var(--bs-sidebar-dark-bg)
}

body[data-sidebar=dark] .vertical-menu .vertical-menu-btn {
    color: var(--bs-sidebar-dark-menu-item-color)
}

body[data-sidebar=dark] #sidebar-menu ul li a {
    color: var(--bs-sidebar-dark-menu-item-color)
}

body[data-sidebar=dark] #sidebar-menu ul li a .nav-icon {
    color: var(--bs-sidebar-dark-menu-item-icon-color)
}

body[data-sidebar=dark] #sidebar-menu ul li a:hover {
    color: var(--bs-sidebar-dark-menu-item-hover-color)
}

body[data-sidebar=dark] #sidebar-menu ul li a:hover .nav-icon {
    color: var(--bs-sidebar-dark-menu-item-hover-color)
}

body[data-sidebar=dark] #sidebar-menu ul li ul.sub-menu li a {
    color: var(--bs-sidebar-dark-menu-sub-item-color);
    background-color: transparent
}

body[data-sidebar=dark] #sidebar-menu ul li ul.sub-menu li a:before {
    border-color: var(--bs-sidebar-dark-menu-sub-item-color)
}

body[data-sidebar=dark] #sidebar-menu ul li ul.sub-menu li a:hover {
    color: var(--bs-sidebar-dark-menu-item-hover-color)
}

body[data-sidebar=dark] #sidebar-menu ul li ul.sub-menu li a:hover:before {
    background-color: var(--bs-sidebar-dark-menu-item-hover-color);
    border-color: var(--bs-sidebar-dark-menu-item-hover-color)
}

body[data-sidebar=dark] #sidebar-menu ul li.mm-active {
    color: var(--bs-sidebar-dark-menu-item-active-color) !important
}

body[data-sidebar=dark] #sidebar-menu ul li.mm-active>a {
    background-color: var(--bs-sidebar-dark-menu-item-active-bg-color);
    color: var(--bs-sidebar-dark-menu-item-active-color) !important
}

body[data-sidebar=dark] #sidebar-menu ul li.mm-active>a .nav-icon {
    color: var(--bs-sidebar-dark-menu-item-active-color) !important
}

body[data-sidebar=dark] #sidebar-menu ul li.mm-active .mm-show .mm-active>a {
    color: var(--bs-sidebar-dark-menu-item-active-color) !important
}

body[data-sidebar=dark] #sidebar-menu ul li.mm-active .mm-show .mm-active>a:before {
    border-color: var(--bs-sidebar-dark-menu-item-active-color) !important;
    background-color: var(--bs-sidebar-dark-menu-item-active-color) !important
}

body[data-sidebar=dark][data-sidebar-size=sm] {
    min-height: 1300px
}

body[data-sidebar=dark][data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li:hover>a {
    background: var(--bs-sidebar-dark-bg);
    color: var(--bs-sidebar-dark-menu-item-hover-color)
}

body[data-sidebar=dark][data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li:hover>a .nav-icon {
    color: var(--bs-sidebar-dark-menu-item-hover-color)
}

body[data-sidebar=dark][data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li:hover>ul a {
    color: var(--bs-sidebar-dark-menu-sub-item-color)
}

body[data-sidebar=dark][data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li:hover>ul a:hover {
    color: var(--bs-sidebar-dark-menu-item-hover-color)
}

body[data-sidebar=dark][data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul ul {
    background-color: var(--bs-sidebar-dark-bg)
}

body[data-sidebar=dark] .menu-title {
    color: var(--bs-sidebar-dark-menu-item-color)
}

body[data-layout=horizontal] .main-content {
    margin-left: 0 
}

body[data-sidebar-size=md] .navbar-brand-box {
    width: 160px
}

@media (max-width:991.98px) {
    body[data-sidebar-size=md] .navbar-brand-box {
        width: auto
    }
}

body[data-sidebar-size=md] .vertical-menu {
    width: 160px;
    text-align: center
}

body[data-sidebar-size=md] .vertical-menu .badge,
body[data-sidebar-size=md] .vertical-menu .has-arrow:after {
    display: none !important
}

body[data-sidebar-size=md] .vertical-menu .vertical-menu-btn {
    display: none
}

body[data-sidebar-size=md] .main-content {
    margin-left: 160px
}

body[data-sidebar-size=md] #page-topbar {
    margin-left: 160px
}

@media (max-width:991.98px) {
    body[data-sidebar-size=md] #page-topbar {
        margin-left: 0
    }
}

body[data-sidebar-size=md] .footer {
    left: 160px
}

@media (max-width:991.98px) {
    body[data-sidebar-size=md] .footer {
        left: 0
    }
}

body[data-sidebar-size=md] #sidebar-menu ul li.menu-title {
    background-color: var(--bs-sidebar-bg)
}

body[data-sidebar-size=md] #sidebar-menu ul li .menu-item {
    margin-left: 0
}

body[data-sidebar-size=md] #sidebar-menu ul li a {
    padding: .75rem 1.1rem;
    display: block
}

body[data-sidebar-size=md] #sidebar-menu ul li a .nav-icon {
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 auto;
    padding-bottom: .2em;
    top: -2px
}

body[data-sidebar-size=md] #sidebar-menu ul li ul.sub-menu li a {
    padding-left: 1.5rem
}

body[data-sidebar-size=md] #sidebar-menu ul li ul.sub-menu li a:before {
    display: none
}

body[data-sidebar-size=md][data-sidebar=dark] #sidebar-menu ul li.menu-title {
    background-color: var(--bs-sidebar-dark-bg)
}

body[data-sidebar-size=md]:not(.vertical-collpsed) .navbar-header .vertical-menu-btn {
    display: block
}

body[data-sidebar-size=md][data-sidebar-size=sm] .main-content {
    margin-left: 70px
}

body[data-sidebar-size=md][data-sidebar-size=sm] .vertical-menu #sidebar-menu {
    text-align: left
}

body[data-sidebar-size=md][data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li>a .nav-icon {
    display: inline-block
}

body[data-sidebar-size=md][data-sidebar-size=sm] #page-topbar,
body[data-sidebar-size=md][data-sidebar-size=sm] .footer {
    left: 70px
}

body[data-sidebar=brand] .logo-txt {
    color: #fff !important
}

body[data-sidebar=brand] .vertical-menu {
    background-color: #5466f9;
    border-color: #5466f9
}

body[data-sidebar=brand] .vertical-menu::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: .075
}

body[data-sidebar=brand] .vertical-menu .vertical-menu-btn {
    color: rgba(255, 255, 255, .8)
}

body[data-sidebar=brand] .navbar-brand-box {
    background-color: #5466f9
}

body[data-sidebar=brand] .navbar-brand-box .logo-dark {
    display: none
}

body[data-sidebar=brand] .navbar-brand-box .logo-light {
    display: block
}

body[data-sidebar=brand] #sidebar-menu ul li.menu-title {
    color: rgba(255, 255, 255, .7)
}

body[data-sidebar=brand] #sidebar-menu ul li a {
    color: rgba(255, 255, 255, .7)
}

body[data-sidebar=brand] #sidebar-menu ul li a .nav-icon {
    color: rgba(255, 255, 255, .7)
}

body[data-sidebar=brand] #sidebar-menu ul li a:hover {
    color: #fff
}

body[data-sidebar=brand] #sidebar-menu ul li a:hover .nav-icon {
    color: #fff
}

body[data-sidebar=brand] #sidebar-menu ul li ul.sub-menu li a {
    color: rgba(255, 255, 255, .6)
}

body[data-sidebar=brand] #sidebar-menu ul li ul.sub-menu li a:before {
    border-color: rgba(255, 255, 255, .6)
}

body[data-sidebar=brand] #sidebar-menu ul li ul.sub-menu li a:hover {
    color: #fff
}

body[data-sidebar=brand] #sidebar-menu ul li ul.sub-menu li a:hover:before {
    background-color: #fff;
    border-color: #fff
}

body[data-sidebar=brand] #sidebar-menu ul li .badge {
    background-color: #fff
}

body[data-sidebar=brand] #sidebar-menu ul li.mm-active {
    color: #fff !important
}

body[data-sidebar=brand] #sidebar-menu ul li.mm-active>a {
    background: rgba(255, 255, 255, .15);
    color: #fff !important
}

body[data-sidebar=brand] #sidebar-menu ul li.mm-active>a:before {
    background-color: #fff
}

body[data-sidebar=brand] #sidebar-menu ul li.mm-active>a .nav-icon {
    color: #fff !important
}

body[data-sidebar=brand] #sidebar-menu ul li.mm-active .mm-show .mm-active>a {
    background-color: transparent;
    color: #fff !important
}

body[data-sidebar=brand] #sidebar-menu ul li.mm-active .mm-show .mm-active>a:before {
    display: none
}

body[data-sidebar=brand][data-topbar=light] .navbar-brand-box {
    background-color: #5466f9
}

body[data-sidebar=brand][data-topbar=light] .navbar-brand-box .logo-dark {
    display: none
}

body[data-sidebar=brand][data-topbar=light] .navbar-brand-box .logo-light {
    display: block
}

body[data-sidebar=brand][data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li:hover>a {
    background-color: #7e72d1;
    color: #fff
}

body[data-sidebar=brand][data-sidebar-size=sm] .vertical-menu #sidebar-menu>ul>li:hover>a .nav-icon {
    color: #fff
}

body[data-layout=horizontal] #page-topbar {
    margin-left: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

body[data-layout=horizontal] .navbar-brand-box {
    display: block;
    width: auto;
    border: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative
}

@media (min-width:992px) {
    body[data-layout=horizontal] .navbar-brand-box {
        padding-left: 0;
        margin-right: 10px
    }
}

body[data-layout=horizontal] .page-content {
    margin-top: calc(85px - 55px);
    padding: 22px 0 60px 0
}

@media (max-width:991.98px) {
    body[data-layout=horizontal] .page-content {
        padding-top: 22px
    }
}

@media (min-width:992px) {
    body[data-layout=horizontal] .navbar-header {
        padding-left: calc(22px / 2);
        padding-right: calc(22px / 2)
    }
}

body[data-layout=horizontal][data-sidebar-size=sm] .navbar-brand-box {
    width: auto !important
}

.topnav {
    padding: 0 calc(22px / 2);
    z-index: 100
}

@media (max-width:991.98px) {
    .topnav {
        position: fixed;
        left: 0;
        right: 0;
        top: 85px;
        z-index: 100;
        background: var(--bs-topnav-bg);
        -webkit-box-shadow: 0 2px 3px rgba(52, 58, 64, .06);
        box-shadow: 0 2px 3px rgba(52, 58, 64, .06)
    }
}

.topnav .topnav-menu {
    margin: 0;
    padding: 0
}

.topnav .navbar-nav .nav-item {
    margin: 0 .9rem
}

@media (min-width:992px) {
    .topnav .navbar-nav .nav-item:first-child {
        margin-left: 0
    }
    .topnav .navbar-nav .nav-item:last-child {
        margin-right: 0
    }
}

.topnav .navbar-nav .nav-link {
    font-size: 14.4px;
    position: relative;
    padding: 0;
    color: var(--bs-menu-item-color);
    font-weight: 400;
    line-height: 85px
}

.topnav .navbar-nav .nav-link i {
    font-size: 18px;
    margin-right: 5px;
    vertical-align: middle;
    margin-top: -2px
}

.topnav .navbar-nav .nav-link svg {
    height: 16px;
    width: 16px;
    color: var(--bs-menu-item-color);
    margin-right: 7px;
    margin-top: -1px
}

.topnav .navbar-nav .nav-link:focus,
.topnav .navbar-nav .nav-link:hover {
    color: var(--bs-menu-item-active-color);
    background-color: transparent
}

.topnav .navbar-nav .nav-link:focus svg,
.topnav .navbar-nav .nav-link:hover svg {
    color: var(--bs-menu-item-active-color)
}

.topnav .navbar-nav .dropdown-item {
    color: var(--bs-menu-item-color);
    background: 0 0
}

.topnav .navbar-nav .dropdown-item.active,
.topnav .navbar-nav .dropdown-item:hover {
    color: var(--bs-menu-item-active-color)
}

.topnav .navbar-nav .nav-item .nav-link.active {
    color: var(--bs-menu-item-active-color)
}

.topnav .navbar-nav .nav-item .nav-link.active svg {
    color: var(--bs-menu-item-active-color)
}

@media (min-width:992px) {
    .topnav .navbar-nav .nav-item.active>.nav-link span {
        position: relative
    }
    .topnav .navbar-nav .nav-item.active>.nav-link span::before {
        content: "";
        height: 2px;
        width: 100%;
        background-color: var(--bs-menu-item-active-border-color);
        position: absolute;
        bottom: -10px;
        left: 0
    }
}

.topnav .navbar-nav .dropdown.active>a {
    color: var(--bs-menu-item-active-color);
    background-color: transparent
}

.topnav .navbar-nav .dropdown.active>a svg {
    color: var(--bs-menu-item-active-color)
}

.topnav .menu-title {
    padding: 12px 24px !important
}

@media (max-width:991.98px) {
    .topnav .menu-title {
        padding: 12px 16px !important
    }
}

@media (min-width:1200px) {
    body[data-layout=horizontal] .container-fluid,
    body[data-layout=horizontal] .navbar-header {
        max-width: 85%
    }
}

@media (min-width:992px) {
    .topnav .dropdown-item {
        padding: .5rem 1.5rem;
        min-width: 180px
    }
    .topnav .dropdown.mega-dropdown .mega-dropdown-menu {
        left: 0;
        right: auto
    }
    .topnav .dropdown .dropdown-menu {
        margin-top: 0;
        border-radius: 0 0 .25rem .25rem
    }
    .topnav .dropdown .dropdown-menu .arrow-down::after {
        right: 15px;
        -webkit-transform: rotate(-135deg) translateY(-50%);
        transform: rotate(-135deg) translateY(-50%);
        position: absolute
    }
    .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
        position: absolute;
        top: 0 !important;
        left: 100%;
        display: none
    }
    .topnav .dropdown:hover>.dropdown-menu {
        display: block
    }
    .topnav .dropdown:hover>.dropdown-menu>.dropdown:hover>.dropdown-menu {
        display: block
    }
    .navbar-toggle {
        display: none
    }
}

.arrow-down {
    display: inline-block
}

.arrow-down:after {
    border-color: initial;
    border-style: solid;
    border-width: 0 0 1px 1px;
    content: "";
    height: .4em;
    display: inline-block;
    right: 5px;
    top: 50%;
    margin-left: 5px;
    -webkit-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%);
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
    width: .4em
}

.dash-header-box {
    background-color: rgba(255, 255, 255, .1) !important;
    margin-bottom: calc(22px + 16px)
}

.dash-content {
    background: var(--bs-header-dark-bg);
    border-top: 1px solid rgba(255, 255, 255, .1)
}

.dash-troggle-icon {
    width: 50px;
    height: 50px;
    line-height: 60px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    margin-top: -20px;
    border-radius: 50%;
    background: var(--bs-header-dark-bg)
}

.dash-troggle-icon i {
    font-size: 20px;
    color: #fff
}

.dash-troggle-icon.collapsed i:before {
    content: "\ea2f"
}

@media (max-width:1199.98px) {
    .topnav-menu .navbar-nav li:last-of-type .dropdown .dropdown-menu {
        right: 100%;
        left: auto
    }
}

@media (max-width:991.98px) {
    .navbar-brand-box .logo-dark {
        display: block
    }
    .navbar-brand-box .logo-dark span.logo-sm {
        display: block
    }
    .navbar-brand-box .logo-light {
        display: none
    }
    .topnav {
        max-height: 360px;
        overflow-y: auto;
        padding: 0
    }
    .topnav .navbar-nav .nav-link {
        padding: .75rem 1.1rem;
        line-height: inherit
    }
    .topnav .dropdown .dropdown-menu {
        background-color: transparent;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding-left: 20px
    }
    .topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl {
        width: auto
    }
    .topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl .row {
        margin: 0
    }
    .topnav .dropdown .dropdown-item {
        position: relative;
        background-color: transparent
    }
    .topnav .dropdown .dropdown-item.active,
    .topnav .dropdown .dropdown-item:active {
        color: #5466f9
    }
    .topnav .arrow-down::after {
        right: 15px;
        position: absolute
    }
}

body[data-layout=horizontal][data-topbar=colored] #page-topbar {
    background-color: #5466f9;
    -webkit-box-shadow: none;
    box-shadow: none
}

body[data-layout=horizontal][data-topbar=colored] .logo-dark {
    display: none
}

body[data-layout=horizontal][data-topbar=colored] .logo-light {
    display: block
}

body[data-layout=horizontal][data-topbar=colored] .app-search .form-control {
    background-color: rgba(var(--bs-topbar-search-bg), .07);
    color: #fff
}

body[data-layout=horizontal][data-topbar=colored] .app-search input.form-control::-webkit-input-placeholder,
body[data-layout=horizontal][data-topbar=colored] .app-search span {
    color: rgba(255, 255, 255, .5)
}

body[data-layout=horizontal][data-topbar=colored] .header-item {
    color: var(--bs-header-dark-item-color)
}

body[data-layout=horizontal][data-topbar=colored] .header-item:hover {
    color: var(--bs-header-dark-item-color)
}

body[data-layout=horizontal][data-topbar=colored] .navbar-header .dropdown .show.header-item {
    background-color: rgba(255, 255, 255, .1)
}

body[data-layout=horizontal][data-topbar=colored] .noti-icon i {
    color: var(--bs-header-dark-item-color)
}

@media (min-width:992px) {
    body[data-layout=horizontal][data-topbar=colored] .topnav {
        background-color: #5466f9
    }
    body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav .nav-link {
        color: rgba(255, 255, 255, .6)
    }
    body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav .nav-link:focus,
    body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav .nav-link:hover {
        color: rgba(255, 255, 255, .9)
    }
    body[data-layout=horizontal][data-topbar=colored] .topnav .navbar-nav>.dropdown.active>a {
        color: rgba(255, 255, 255, .9) !important
    }
}

body[data-layout-mode=dark] .topnav .navbar-nav .nav-link {
    color: var(--bs-menu-dark-item-color)
}

body[data-layout-mode=dark] .topnav .navbar-nav .nav-link svg {
    color: var(--bs-menu-dark-item-color)
}

body[data-layout-mode=dark] .topnav .navbar-nav .nav-link:focus,
body[data-layout-mode=dark] .topnav .navbar-nav .nav-link:hover {
    color: var(--bs-menu-dark-item-active-color);
    background-color: transparent
}

body[data-layout-mode=dark] .topnav .navbar-nav .nav-link:focus svg,
body[data-layout-mode=dark] .topnav .navbar-nav .nav-link:hover svg {
    color: var(--bs-menu-dark-item-active-color)
}

body[data-layout-mode=dark] .topnav .navbar-nav .nav-item .nav-link.active {
    color: var(--bs-menu-dark-item-active-color)
}

body[data-layout-mode=dark] .topnav .navbar-nav .nav-item .nav-link.active svg {
    color: var(--bs-menu-dark-item-active-color)
}

body[data-layout-mode=dark] .topnav .navbar-nav .dropdown.active>a {
    color: var(--bs-menu-dark-item-active-color);
    background-color: transparent
}

body[data-layout-mode=dark] .topnav .navbar-nav .dropdown.active>a svg {
    color: var(--bs-menu-dark-item-active-color)
}

body[data-layout=horizontal][data-topbar=dark] .header-item {
    color: var(--bs-header-dark-item-color)
}

body[data-layout=horizontal][data-topbar=dark] .header-item:hover {
    color: var(--bs-header-dark-item-color)
}

body[data-layout=horizontal][data-topbar=dark] .header-profile-user {
    border-color: rgba(255, 255, 255, .25)
}

body[data-layout=horizontal][data-topbar=dark] .user-name {
    color: var(--bs-header-dark-item-color)
}

body[data-layout=horizontal][data-topbar=dark] .logo-dark {
    display: none
}

body[data-layout=horizontal][data-topbar=dark] .logo-light {
    display: block
}

body[data-layout=horizontal][data-topbar=dark] .logo-txt {
    color: #fff !important
}

body[data-layout=horizontal][data-topbar=dark] .app-search .form-control {
    background-color: var(--bs-header-dark-bg);
    color: #fff
}

body[data-layout=horizontal][data-topbar=dark] .app-search input.form-control::-webkit-input-placeholder,
body[data-layout=horizontal][data-topbar=dark] .app-search span {
    color: rgba(255, 255, 255, .5)
}

body[data-topbar=dark] .topnav .navbar-nav .nav-link {
    color: var(--bs-menu-dark-item-color)
}

body[data-topbar=dark] .topnav .navbar-nav .nav-link:focus,
body[data-topbar=dark] .topnav .navbar-nav .nav-link:hover {
    color: var(--bs-menu-dark-item-hover-color)
}

body[data-topbar=dark] .topnav .navbar-nav .nav-link:focus i,
body[data-topbar=dark] .topnav .navbar-nav .nav-link:focus svg,
body[data-topbar=dark] .topnav .navbar-nav .nav-link:hover i,
body[data-topbar=dark] .topnav .navbar-nav .nav-link:hover svg {
    color: var(--bs-menu-dark-item-hover-color)
}

@media (max-width:991.98px) {
    body[data-topbar=dark] .topnav .navbar-nav .nav-link {
        color: var(--bs-menu-item-color)
    }
    body[data-topbar=dark] .topnav .navbar-nav .nav-link:focus,
    body[data-topbar=dark] .topnav .navbar-nav .nav-link:hover {
        color: var(--bs-menu-item-active-color)
    }
    body[data-topbar=dark] .topnav .navbar-nav .nav-link:focus i,
    body[data-topbar=dark] .topnav .navbar-nav .nav-link:focus svg,
    body[data-topbar=dark] .topnav .navbar-nav .nav-link:hover i,
    body[data-topbar=dark] .topnav .navbar-nav .nav-link:hover svg {
        color: var(--bs-menu-item-active-color)
    }
}

body[data-topbar=dark] .topnav .navbar-nav .nav-item .nav-link.active {
    color: var(--bs-menu-dark-item-active-color)
}

body[data-topbar=dark] .topnav .navbar-nav .nav-item .nav-link.active i,
body[data-topbar=dark] .topnav .navbar-nav .nav-item .nav-link.active svg {
    color: var(--bs-menu-dark-item-active-color)
}

@media (max-width:991.98px) {
    body[data-topbar=dark] .topnav .navbar-nav .nav-item .nav-link.active {
        color: var(--bs-menu-item-active-color)
    }
    body[data-topbar=dark] .topnav .navbar-nav .nav-item .nav-link.active i,
    body[data-topbar=dark] .topnav .navbar-nav .nav-item .nav-link.active svg {
        color: var(--bs-menu-item-active-color)
    }
}

body[data-topbar=dark] .topnav .navbar-nav .dropdown.active>a {
    color: var(--bs-menu-dark-item-active-color);
    background-color: transparent
}

body[data-topbar=dark] .topnav .navbar-nav .dropdown .dropdown.active>a {
    color: var(--bs-menu-item-active-color)
}

@media (max-width:991.98px) {
    body[data-topbar=dark] .topnav .navbar-nav .dropdown.active>a {
        color: var(--bs-menu-item-active-color);
        background-color: transparent
    }
    body[data-topbar=dark] .topnav .navbar-nav .dropdown .dropdown.active>a {
        color: var(--bs-menu-item-active-color)
    }
}

.hori-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(52, 58, 64, .35);
    z-index: 999;
    display: none
}

.horimenu-enabled .hori-overlay {
    display: block
}

body[data-layout-size=boxed] {
    background-color: var(--bs-boxed-body-bg)
}

body[data-layout-size=boxed] #layout-wrapper {
    background-color: var(--bs-body-bg);
    max-width: 1400px;
    margin: 0 auto;
    -webkit-box-shadow: 0 2px 3px rgba(52, 58, 64, .06);
    box-shadow: 0 2px 3px rgba(52, 58, 64, .06)
}

body[data-layout-size=boxed] #page-topbar {
    max-width: calc(1400px - 255px)
}

body[data-layout-size=boxed] .footer {
    margin: 0 auto;
    max-width: calc(1400px - 255px)
}

body[data-layout-size=boxed][data-sidebar-size=sm] #page-topbar {
    max-width: calc(1400px - 70px)
}

body[data-layout-size=boxed][data-sidebar-size=sm] .footer {
    max-width: calc(1400px - 70px)
}

@media (min-width:769px) {
    body[data-layout-size=boxed][data-sidebar-size=sm] .main-content {
        min-height: 1850px
    }
}

body[data-layout-size=boxed][data-sidebar-size=md] #page-topbar {
    max-width: calc(1400px - 160px)
}

body[data-layout-size=boxed][data-sidebar-size=md] .footer {
    max-width: calc(1400px - 160px)
}

body[data-layout=horizontal][data-layout-size=boxed] #layout-wrapper,
body[data-layout=horizontal][data-layout-size=boxed] #page-topbar,
body[data-layout=horizontal][data-layout-size=boxed] .footer {
    max-width: 100%
}

body[data-layout=horizontal][data-layout-size=boxed] .container-fluid,
body[data-layout=horizontal][data-layout-size=boxed] .navbar-header {
    max-width: 1400px
}

@media (min-width:992px) {
    body[data-layout-scrollable=true] #page-topbar,
    body[data-layout-scrollable=true] .vertical-menu {
        position: absolute
    }
    body[data-layout-scrollable=true] .navbar-brand-box {
        position: absolute
    }
}

@media (min-width:992px) {
    body[data-layout-scrollable=true][data-layout=horizontal] #page-topbar {
        position: absolute
    }
    body[data-layout-scrollable=true][data-layout=horizontal] .navbar-brand-box {
        position: relative
    }
}

.avatar-xs {
    height: 1rem;
    width: 1rem
}

.avatar-sm {
    height: 2rem;
    width: 2rem
}

.avatar {
    height: 2.6rem;
    width: 2.6rem
}

.avatar-md {
    height: 3.6rem;
    width: 3.6rem
}

.avatar-lg {
    height: 5rem;
    width: 5rem
}

.avatar-xl {
    height: 6rem;
    width: 6rem
}

.avatar-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #5466f9;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 500;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%
}

.avatar-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 8px
}

.avatar-group .avatar-group-item {
    margin-left: -8px;
    border: 2px solid var(--bs-card-bg);
    border-radius: 50%;
    -webkit-transition: all .2s;
    transition: all .2s
}

.avatar-group .avatar-group-item:hover {
    position: relative;
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px)
}

.alert-top-border {
    background-color: var(--bs-card-bg);
    border-color: var(--bs-border-color);
    border-top: 2px solid #f8f7fc;
    color: var(--bs-body-color)
}

.alert-dismissible .btn-close {
    background: 0 0 !important
}

.alert-dismissible .btn-close::after {
    background: 0 0 !important;
    content: "\f0156" !important;
    font-size: 18px;
    line-height: 15px;
    font-family: "Material Design Icons" !important
}

.alert-outline.alert-dark {
    color: var(--bs-dark)
}

.alert-top-border.alert-primary {
    border-top-color: #5466f9;
    color: #5466f9
}

.alert-outline.alert-primary {
    border: 1px solid rgba(119, 106, 207, .8);
    color: #5466f9
}

.alert-top-border.alert-secondary {
    border-top-color: #969ba5;
    color: #969ba5
}

.alert-outline.alert-secondary {
    border: 1px solid rgba(150, 155, 165, .8);
    color: #969ba5
}

.alert-top-border.alert-success {
    border-top-color: #6dcba3;
    color: #6dcba3
}

.alert-outline.alert-success {
    border: 1px solid rgba(109, 203, 163, .8);
    color: #6dcba3
}

.alert-top-border.alert-info {
    border-top-color: #5fd0f3;
    color: #5fd0f3
}

.alert-outline.alert-info {
    border: 1px solid rgba(95, 208, 243, .8);
    color: #5fd0f3
}

.alert-top-border.alert-warning {
    border-top-color: #f7cc53;
    color: #f7cc53
}

.alert-outline.alert-warning {
    border: 1px solid rgba(247, 204, 83, .8);
    color: #f7cc53
}

.alert-top-border.alert-danger {
    border-top-color: #e1665d;
    color: #e1665d
}

.alert-outline.alert-danger {
    border: 1px solid rgba(225, 102, 93, .8);
    color: #e1665d
}

.alert-top-border.alert-pink {
    border-top-color: #e83e8c;
    color: #e83e8c
}

.alert-outline.alert-pink {
    border: 1px solid rgba(232, 62, 140, .8);
    color: #e83e8c
}

.alert-top-border.alert-light {
    border-top-color: #f8f7fc;
    color: #f8f7fc
}

.alert-outline.alert-light {
    border: 1px solid rgba(248, 247, 252, .8);
    color: #f8f7fc
}

.alert-top-border.alert-dark {
    border-top-color: #343a40;
    color: #343a40
}

.alert-outline.alert-dark {
    border: 1px solid rgba(52, 58, 64, .8);
    color: #343a40
}

.alert-top-border.alert-purple {
    border-top-color: #3966d7;
    color: #3966d7
}

.alert-outline.alert-purple {
    border: 1px solid rgba(57, 102, 215, .8);
    color: #3966d7
}

.custom-accordion .accordion-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 7px;
    background-color: #eff0f2;
    padding: 12px 20px;
    color: #1a1823;
    font-weight: 600;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.custom-accordion .accordion-list.collapsed i.accor-plus-icon:before {
    content: "\f0415"
}

.custom-accordion .accordion-list .accor-plus-icon {
    display: inline-block;
    font-size: 16px;
    height: 24px;
    width: 24px;
    line-height: 22px;
    background-color: var(--bs-card-bg);
    text-align: center;
    border-radius: 50%
}

.custom-accordion a.collapsed i.accor-down-icon:before {
    content: "\f0140"
}

.custom-accordion .card-body {
    color: var(--bs-text-muted)
}

[data-layout-mode=dark] .accordion-button:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23adb5bd'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.font-size-10 {
    font-size: 10px !important
}

.font-size-11 {
    font-size: 11px !important
}

.font-size-12 {
    font-size: 12px !important
}

.font-size-13 {
    font-size: 13px !important
}

.font-size-14 {
    font-size: 14px !important
}

.font-size-15 {
    font-size: 15px !important
}

.font-size-16 {
    font-size: 16px !important
}

.font-size-17 {
    font-size: 17px !important
}

.font-size-18 {
    font-size: 18px !important
}

.font-size-20 {
    font-size: 20px !important
}

.font-size-22 {
    font-size: 22px !important
}

.font-size-24 {
    font-size: 24px !important
}

.font-size-26 {
    font-size: 26px !important
}

.fw-medium {
    font-weight: 500
}

.fw-semibold {
    font-weight: 600
}

.bg-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    opacity: .7;
    background-color: #000
}

.bg-overlay-gradient {
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    opacity: .85;
    background: var(--bs-header-dark-bg)
}

.social-list-item {
    height: 2.4rem;
    width: 2.4rem;
    line-height: calc(2.4rem - 2px);
    display: block;
    border: 1px solid var(--bs-card-bg);
    border-radius: 50%;
    color: var(--bs-text-muted);
    text-align: center;
    -webkit-transition: all .4s;
    transition: all .4s
}

.social-list-item:hover {
    color: #343a40
}

.w-xs {
    min-width: 80px
}

.w-sm {
    min-width: 95px
}

.w-md {
    min-width: 110px
}

.w-lg {
    min-width: 140px
}

.w-xl {
    min-width: 160px
}

.alert-dismissible .btn-close {
    font-size: 10px;
    padding: 1.05rem 1.25rem
}

.border-dark {
    border-color: var(--bs-dark) !important
}

.border-light {
    border-color: var(--bs-light) !important
}

[data-layout-mode=dark] .text-body {
    color: var(--bs-gray-400) !important
}

[data-layout-mode=dark] .btn-close {
    -webkit-filter: invert(1) grayscale(100%) brightness(200%);
    filter: invert(1) grayscale(100%) brightness(200%)
}

[data-layout-mode=dark] .btn-close-white {
    -webkit-filter: invert(0) grayscale(100%) brightness(200%);
    filter: invert(0) grayscale(100%) brightness(200%)
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bs-card-bg);
    z-index: 9999
}

#status {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: -20px 0 0 -20px
}

.spinner .spin-icon {
    font-size: 48px;
    color: #5466f9;
    position: relative;
    display: inline-block;
    -webkit-animation: spin 1.6s infinite linear;
    animation: spin 1.6s infinite linear
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

[type=email]::-webkit-input-placeholder,
[type=number]::-webkit-input-placeholder,
[type=tel]::-webkit-input-placeholder,
[type=url]::-webkit-input-placeholder {
    text-align: left
}

[type=email]::-moz-placeholder,
[type=number]::-moz-placeholder,
[type=tel]::-moz-placeholder,
[type=url]::-moz-placeholder {
    text-align: left
}

[type=email]:-ms-input-placeholder,
[type=number]:-ms-input-placeholder,
[type=tel]:-ms-input-placeholder,
[type=url]:-ms-input-placeholder {
    text-align: left
}

[type=email]::-ms-input-placeholder,
[type=number]::-ms-input-placeholder,
[type=tel]::-ms-input-placeholder,
[type=url]::-ms-input-placeholder {
    text-align: left
}

[type=email]::placeholder,
[type=number]::placeholder,
[type=tel]::placeholder,
[type=url]::placeholder {
    text-align: left
}

.form-check {
    position: relative;
    text-align: left
}

.form-check-right {
    padding-left: 0;
    display: inline-block;
    padding-right: 1.5em
}

.form-check-right .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: -1.5em
}

.form-check-right .form-check-label {
    display: block
}

.form-checkbox-outline .form-check-input {
    border-width: 2px;
    background-color: var(--bs-card-bg)
}

.form-checkbox-outline .form-check-input:active {
    -webkit-filter: none;
    filter: none
}

.form-checkbox-outline .form-check-input:checked {
    background-color: var(--bs-card-bg) !important
}

.form-checkbox-outline .form-check-input:checked[type=checkbox] {
    background-image: none
}

.form-checkbox-outline .form-check-input:checked:after {
    position: absolute;
    content: "\f012c";
    font-family: "Material Design Icons";
    top: -4px !important;
    left: 1px;
    font-size: 16px;
    color: #343a40
}

.form-radio-outline .form-check-input {
    border-width: 2px;
    background-color: var(--bs-card-bg)
}

.form-radio-outline .form-check-input:active {
    -webkit-filter: none;
    filter: none
}

.form-radio-outline .form-check-input:checked {
    background-color: var(--bs-card-bg) !important
}

.form-radio-outline .form-check-input:checked[type=checkbox] {
    background-image: none
}

.form-radio-outline .form-check-input:checked:after {
    position: absolute;
    content: "\f0765";
    font-family: "Material Design Icons";
    top: 6px !important;
    left: 4px;
    font-size: 6px
}

.form-check-primary .form-check-input:checked {
    background-color: #5466f9;
    border-color: #5466f9
}

.form-radio-primary .form-check-input:checked {
    border-color: #5466f9;
    background-color: #5466f9
}

.form-radio-primary .form-check-input:checked:after {
    color: #5466f9
}

.form-check-secondary .form-check-input:checked {
    background-color: #969ba5;
    border-color: #969ba5
}

.form-radio-secondary .form-check-input:checked {
    border-color: #969ba5;
    background-color: #969ba5
}

.form-radio-secondary .form-check-input:checked:after {
    color: #969ba5
}

.form-check-success .form-check-input:checked {
    background-color: #6dcba3;
    border-color: #6dcba3
}

.form-radio-success .form-check-input:checked {
    border-color: #6dcba3;
    background-color: #6dcba3
}

.form-radio-success .form-check-input:checked:after {
    color: #6dcba3
}

.form-check-info .form-check-input:checked {
    background-color: #5fd0f3;
    border-color: #5fd0f3
}

.form-radio-info .form-check-input:checked {
    border-color: #5fd0f3;
    background-color: #5fd0f3
}

.form-radio-info .form-check-input:checked:after {
    color: #5fd0f3
}

.form-check-warning .form-check-input:checked {
    background-color: #f7cc53;
    border-color: #f7cc53
}

.form-radio-warning .form-check-input:checked {
    border-color: #f7cc53;
    background-color: #f7cc53
}

.form-radio-warning .form-check-input:checked:after {
    color: #f7cc53
}

.form-check-danger .form-check-input:checked {
    background-color: #e1665d;
    border-color: #e1665d
}

.form-radio-danger .form-check-input:checked {
    border-color: #e1665d;
    background-color: #e1665d
}

.form-radio-danger .form-check-input:checked:after {
    color: #e1665d
}

.form-check-pink .form-check-input:checked {
    background-color: #e83e8c;
    border-color: #e83e8c
}

.form-radio-pink .form-check-input:checked {
    border-color: #e83e8c;
    background-color: #e83e8c
}

.form-radio-pink .form-check-input:checked:after {
    color: #e83e8c
}

.form-check-light .form-check-input:checked {
    background-color: #f8f7fc;
    border-color: #f8f7fc
}

.form-radio-light .form-check-input:checked {
    border-color: #f8f7fc;
    background-color: #f8f7fc
}

.form-radio-light .form-check-input:checked:after {
    color: #f8f7fc
}

.form-check-dark .form-check-input:checked {
    background-color: #343a40;
    border-color: #343a40
}

.form-radio-dark .form-check-input:checked {
    border-color: #343a40;
    background-color: #343a40
}

.form-radio-dark .form-check-input:checked:after {
    color: #343a40
}

.form-check-purple .form-check-input:checked {
    background-color: #3966d7;
    border-color: #3966d7
}

.form-radio-purple .form-check-input:checked {
    border-color: #3966d7;
    background-color: #3966d7
}

.form-radio-purple .form-check-input:checked:after {
    color: #3966d7
}

.form-check,
.form-check-input,
.form-check-label {
    cursor: pointer;
    margin-bottom: 0
}

.form-switch-md {
    font-size: 20px;
    min-height: 26px;
    line-height: 26px
}

.form-switch-md .form-check-label {
    font-size: .9rem;
    vertical-align: middle
}

.form-switch-lg {
    font-size: 26px;
    min-height: 36px;
    line-height: 36px
}

.form-switch-lg .form-check-label {
    font-size: .9rem;
    vertical-align: middle
}

.input-group-text {
    margin-bottom: 0
}

.card-radio {
    background-color: var(--bs-card-bg);
    border: 2px solid var(--bs-card-border-color);
    border-radius: .25rem;
    padding: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.card-radio:hover {
    cursor: pointer
}

.card-radio-label {
    display: block
}

.card-radio-input {
    display: none
}

.card-radio-input:checked+.card-radio {
    border-color: #5466f9 !important
}

[data-layout-mode=dark] .form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23e2e5e8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")
}

[data-layout-mode=dark] .form-switch .form-check-input,
[data-layout-mode=dark] .form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23e2e5e8'/%3e%3c/svg%3e")
}

[dir=rtl] input {
    text-align: end
}

.product-img img {
    height: 45px
}

.order-wid-list {
    padding: 11px 0
}

.order-wid-list:last-child {
    padding-bottom: 0
}

.project-list-table {
    border-collapse: separate;
    border-spacing: 0 12px
}

.project-list-table tr {
    background-color: rgba(var(--bs-dark-rgb), .05)
}

.activity-checkout {
    list-style: none
}

.activity-checkout .checkout-icon {
    position: absolute;
    top: -2px;
    left: -21px
}

.activity-checkout .checkout-item {
    position: relative;
    padding-bottom: 16px;
    padding-left: 35px;
    border-left: 2px solid var(--bs-light)
}

.activity-checkout .checkout-item:first-child {
    border-color: #5466f9
}

.activity-checkout .checkout-item:first-child:after {
    background-color: #5466f9
}

.activity-checkout .checkout-item:last-child {
    border-color: transparent
}

.activity-checkout .checkout-item.crypto-activity {
    margin-left: 0
}

.activity-checkout .checkout-item .crypto-date {
    position: absolute;
    top: 3px;
    left: -65px
}

.dash-header-box .col {
    border-right: 2px solid rgba(255, 255, 255, .06)
}

.dash-header-box .col:last-child {
    border: 0
}

@media (min-width:768px) and (max-width:1399.98px) {
    .dash-header-box .col:nth-child(3) {
        border-right: 0
    }
}

@media (max-width:767.98px) {
    .dash-header-box .col {
        border-right: 0;
        border-bottom: 2px solid rgba(255, 255, 255, .06)
    }
}

.swiper-container {
    width: 100%;
    height: 100%
}

.swiper-slide {
    text-align: center;
    width: 100%
}

.dash-product-img img {
    height: 170px;
    cursor: pointer
}

.swiper-button-next:after,
.swiper-button-prev:after {
    display: none
}

.swiper-button-next,
.swiper-button-prev {
    top: 38px;
    width: 30px;
    height: 30px;
    background-color: rgba(119, 106, 207, .2);
    color: #5466f9;
    border-radius: .3rem;
    right: 20px !important
}

.swiper-button-prev {
    right: 60px !important;
    left: auto !important
}

[dir=rtl] .swiper-slide {
    margin-right: 30px;
    margin-left: auto !important
}

.swiper-button-next,
.swiper-button-prev {
    color: #5466f9;
    font-size: 16px
}

.dash-product-box {
    position: relative
}

.pricing-badge {
    position: absolute;
    top: 0;
    z-index: 9;
    right: 0;
    width: 100%;
    display: block;
    font-size: 15px;
    padding: 0;
    overflow: hidden;
    height: 100px
}

.pricing-badge .badge {
    float: right;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    right: -67px;
    top: 17px;
    position: relative;
    text-align: center;
    width: 200px;
    font-size: 13px;
    margin: 0;
    padding: 7px 10px;
    font-weight: 500;
    color: #fff;
    background: #5466f9
}

.social-box {
    padding: 18px 0
}

.loyal-customers-box {
    padding: 11px 0
}

.button-items {
    margin-left: -8px;
    margin-bottom: -12px
}

.button-items .btn {
    margin-bottom: 12px;
    margin-left: 8px
}

.search-box .form-control {
    border-radius: 30px;
    padding-left: 40px
}

.search-box .search-icon {
    font-size: 16px;
    position: absolute;
    left: 13px;
    top: 0;
    line-height: 38px
}

.bs-example-modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block
}

.icon-demo-content {
    text-align: center;
    color: #adb5bd
}

.icon-demo-content i,
.icon-demo-content svg {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 36px;
    font-size: 20px;
    margin: 0 auto 16px;
    border: 2px solid var(--bs-gray-300);
    color: var(--bs-gray-500);
    border-radius: 4px;
    -webkit-transition: all .4s;
    transition: all .4s
}

.icon-demo-content svg {
    padding: 6px
}

.icon-demo-content .col-lg-4 {
    margin-top: 24px
}

.icon-demo-content .col-lg-4:hover i,
.icon-demo-content .col-lg-4:hover svg {
    color: #fff;
    background-color: #5466f9;
    border-color: #5466f9;
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
}

.grid-structure .grid-container {
    background-color: #f8f9fa;
    margin-top: 10px;
    font-size: .8rem;
    font-weight: 500;
    padding: 10px 20px
}

.toast:not(:last-child) {
    margin-bottom: .75rem
}

.ribbon {
    position: absolute;
    right: -26px;
    top: 20px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 1px 22px;
    font-size: 13px;
    font-weight: 500
}

.card-radio {
    background-color: var(--bs-card-bg);
    border: 2px solid var(--bs-card-border-color);
    border-radius: .25rem;
    padding: .5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block
}

.card-radio:hover {
    cursor: pointer
}

.card-radio-label {
    display: block
}

.edit-btn {
    width: 35px;
    height: 35px;
    line-height: 40px;
    text-align: center;
    position: absolute;
    right: 25px;
    margin-top: -50px
}

.card-radio-input {
    display: none
}

.card-radio-input:checked+.card-radio {
    border-color: #5466f9 !important
}

.card-radio-input:checked+.card-radio:before {
    content: "\e9da";
    font-family: boxicons;
    position: absolute;
    top: 6px;
    right: 25px;
    font-size: 22px;
    color: #5466f9
}

@media print {
    .footer,
    .navbar-header,
    .page-title-box,
    .right-bar,
    .vertical-menu {
        display: none !important
    }
    .card-body,
    .main-content,
    .page-content,
    .right-bar,
    body {
        padding: 0;
        margin: 0
    }
    .card {
        border: 0
    }
}

[data-simplebar] {
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.simplebar-wrapper {
    overflow: hidden;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit
}

.simplebar-mask {
    direction: inherit;
    position: absolute;
    overflow: hidden;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
    z-index: 0
}

.simplebar-offset {
    direction: inherit !important;
    -webkit-box-sizing: inherit !important;
    box-sizing: inherit !important;
    resize: none !important;
    position: absolute;
    top: 0;
    left: 0 !important;
    bottom: 0;
    right: 0 !important;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch
}

.simplebar-content-wrapper {
    direction: inherit;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    height: 100%;
    width: auto;
    visibility: visible;
    overflow: auto;
    max-width: 100%;
    max-height: 100%;
    scrollbar-width: none;
    padding: 0 !important
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
    display: none
}

.simplebar-content:after,
.simplebar-content:before {
    content: " ";
    display: table
}

.simplebar-placeholder {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    pointer-events: none
}

.simplebar-height-auto-observer-wrapper {
    -webkit-box-sizing: inherit !important;
    box-sizing: inherit !important;
    height: 100%;
    width: 100%;
    max-width: 1px;
    position: relative;
    float: left;
    max-height: 1px;
    overflow: hidden;
    z-index: -1;
    padding: 0;
    margin: 0;
    pointer-events: none;
    -webkit-box-flex: inherit;
    -ms-flex-positive: inherit;
    flex-grow: inherit;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0
}

.simplebar-height-auto-observer {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 1000%;
    width: 1000%;
    min-height: 1px;
    min-width: 1px;
    overflow: hidden;
    pointer-events: none;
    z-index: -1
}

.simplebar-track {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden
}

[data-simplebar].simplebar-dragging .simplebar-content {
    pointer-events: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none
}

[data-simplebar].simplebar-dragging .simplebar-track {
    pointer-events: all
}

.simplebar-scrollbar {
    position: absolute;
    right: 2px;
    width: 6px;
    min-height: 10px
}

.simplebar-scrollbar:before {
    position: absolute;
    content: "";
    background: #a2adb7;
    border-radius: 7px;
    left: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear
}

.simplebar-scrollbar.simplebar-visible:before {
    opacity: .5;
    -webkit-transition: opacity 0s linear;
    transition: opacity 0s linear
}

.simplebar-track.simplebar-vertical {
    top: 0;
    width: 11px
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    top: 2px;
    bottom: 2px
}

.simplebar-track.simplebar-horizontal {
    left: 0;
    height: 11px
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
    height: 100%;
    left: 2px;
    right: 2px
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    right: auto;
    left: 0;
    top: 2px;
    height: 7px;
    min-height: 0;
    min-width: 10px;
    width: auto
}

[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
    right: auto;
    left: 0
}

.hs-dummy-scrollbar-size {
    direction: rtl;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    height: 500px;
    width: 500px;
    overflow-y: hidden;
    overflow-x: scroll
}

.simplebar-hide-scrollbar {
    position: fixed;
    left: 0;
    visibility: hidden;
    overflow-y: scroll;
    scrollbar-width: none
}

.custom-scroll {
    height: 100%
}

.fc td,
.fc th {
    border: 1px solid var(--bs-border-color)
}

.fc .fc-toolbar h2 {
    font-size: 16px;
    line-height: 30px;
    text-transform: uppercase
}

@media (max-width:767.98px) {
    .fc .fc-toolbar .fc-center,
    .fc .fc-toolbar .fc-left,
    .fc .fc-toolbar .fc-right {
        float: none;
        display: block;
        text-align: center;
        clear: both;
        margin: 10px 0
    }
    .fc .fc-toolbar>*>* {
        float: none
    }
    .fc .fc-toolbar .fc-today-button {
        display: none
    }
}

.fc .fc-toolbar .btn {
    text-transform: capitalize
}

.fc .fc-col-header-cell {
    background-color: var(--bs-light)
}

.fc .fc-col-header-cell-cushion {
    display: block;
    padding: 8px 4px
}

.fc .fc-daygrid-day-number {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    margin: 2px
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: rgba(119, 106, 207, .1)
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
    background-color: #5466f9;
    color: #fff
}

.fc .fc-list-event:hover td {
    background: 0 0
}

.fc .fc-list-event-dot {
    border-color: #fff
}

.fc .fc-list-event-title a {
    color: #fff !important
}

.fc .fc-col-header,
.fc .fc-daygrid-body,
.fc .fc-scrollgrid-sync-table {
    width: 100% !important
}

.fc-theme-bootstrap a:not([href]) {
    color: var(--bs-body-color)
}

.fc-event {
    color: #fff
}

.fc th.fc-widget-header {
    background: #eff0f2;
    color: #1a1823;
    line-height: 20px;
    padding: 10px 0;
    text-transform: uppercase;
    font-weight: 700
}

.fc-unthemed .fc-content,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
    border-color: #eff0f2
}

.fc-unthemed td.fc-today {
    background: #fafafb
}

.fc-button {
    background: var(--bs-card-bg);
    border-color: var(--bs-border-color);
    color: #1a1823;
    text-transform: capitalize;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 6px 12px !important;
    height: auto !important
}

.fc-state-active,
.fc-state-disabled,
.fc-state-down {
    background-color: #5466f9;
    color: #fff;
    text-shadow: none
}

.fc-event {
    border-radius: 2px;
    border: none;
    cursor: move;
    font-size: .8125rem;
    margin: 5px 7px;
    padding: 5px 5px;
    text-align: center
}

.fc-event,
.fc-event-dot {
    background-color: #5466f9
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
    background-color: #5466f9
}

.fc-daygrid-dot-event {
    color: #fff !important
}

.fc-daygrid-dot-event .fc-event-title {
    font-weight: 600
}

.fc-daygrid-event-dot {
    border-color: #fff !important;
    color: #fff !important
}

.fc-event .fc-content {
    color: #fff
}

#external-events .external-event {
    text-align: left;
    padding: 8px 16px;
    margin: 6px 0
}

.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark .fc-content {
    color: #f8f7fc
}

[dir=rtl] .fc-header-toolbar {
    direction: ltr !important
}

[dir=rtl] .fc-toolbar>*>:not(:first-child) {
    margin-left: .75em
}

@media (max-width:575.98px) {
    .fc-toolbar {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 16px
    }
}

.noUi-connect {
    background: #5466f9
}

.noUi-handle {
    border-color: var(--bs-light);
    background: var(--bs-card-bg);
    -webkit-box-shadow: inset 0 0 1px #fff, inset 0 1px 7px var(--bs-light), 0 3px 6px -3px var(--bs-light) !important;
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px var(--bs-light), 0 3px 6px -3px var(--bs-light) !important
}

.noUi-horizontal {
    height: 8px
}

.noUi-horizontal .noUi-handle {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    right: -10px;
    top: -7px
}

.noUi-horizontal .noUi-handle::after,
.noUi-horizontal .noUi-handle::before {
    display: none
}

.noUi-horizontal .noUi-handle:focus {
    outline: 0
}

.noUi-connects,
.noUi-touch-area {
    cursor: pointer
}

.noUi-pips-horizontal {
    height: 50px
}

.noUi-vertical {
    width: 8px
}

.noUi-vertical .noUi-handle {
    height: 20px;
    width: 20px;
    right: -7px;
    top: -12px;
    border-radius: 50%
}

.noUi-vertical .noUi-handle::after,
.noUi-vertical .noUi-handle::before {
    display: none
}

.noUi-vertical .noUi-handle:focus {
    outline: 0
}

.noUi-value {
    font-size: 12px
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 6px
}

.noUi-marker-horizontal.noUi-marker {
    display: none
}

.noUi-target {
    background-color: var(--bs-light);
    border-color: var(--bs-light);
    -webkit-box-shadow: inset 0 1px 1px var(--bs-light), 0 3px 6px -5px var(--bs-light);
    box-shadow: inset 0 1px 1px var(--bs-light), 0 3px 6px -5px var(--bs-light)
}

.noUi-touch-area:focus {
    outline: 0
}

#blue,
#green,
#red {
    margin: 10px;
    display: inline-block;
    height: 200px
}

#colorpicker {
    height: 240px;
    width: 310px;
    margin: 0 auto;
    padding: 10px;
    border: 1px solid var(--bs-border-color)
}

#result {
    margin: 60px 26px;
    height: 100px;
    width: 100px;
    display: inline-block;
    vertical-align: top;
    border: 1px solid var(--bs-border-color);
    -webkit-box-shadow: 0 0 3px;
    box-shadow: 0 0 3px;
    border-radius: 7px
}

#red .noUi-connect {
    background: #e1665d
}

#green .noUi-connect {
    background: #6dcba3
}

#blue .noUi-connect {
    background: #5466f9
}

.form-control.keyboard {
    max-width: 340px !important
}

#input-number,
#input-select {
    padding: 7px;
    margin: 15px 5px 5px;
    width: 70px
}

.example-val {
    font-size: 12px;
    color: var(--bs-text-muted);
    display: block;
    margin: 15px 0
}

.example-val:before {
    content: "Value: ";
    font-size: 12px;
    font-weight: 600
}

.noUi-tooltip {
    display: none
}

.noUi-active .noUi-tooltip {
    display: block
}

.c-1-color {
    background: #e1665d
}

.c-2-color {
    background: #f7cc53
}

.c-3-color {
    background: #6dcba3
}

.c-4-color {
    background: #5466f9
}

.c-5-color {
    background: #3966d7
}

#slider-toggle {
    height: 50px
}

#slider-toggle.off .noUi-handle {
    border-color: #e1665d
}

.swal2-container .swal2-title {
    font-size: 24px;
    font-weight: 500
}

.swal2-content {
    font-size: 16px
}

.swal2-icon.swal2-question {
    border-color: #5fd0f3;
    color: #5fd0f3
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
    background-color: #6dcba3
}

.swal2-icon.swal2-success .swal2-success-ring {
    border-color: rgba(109, 203, 163, .3)
}

.swal2-icon.swal2-warning {
    border-color: #f7cc53;
    color: #f7cc53
}

.swal2-styled:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.swal2-progress-steps .swal2-progress-step {
    background: #5466f9
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
    background: #5466f9
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step~.swal2-progress-step,
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step~.swal2-progress-step-line {
    background: rgba(119, 106, 207, .3)
}

.swal2-progress-steps .swal2-progress-step-line {
    background: #5466f9
}

.swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
    border-left-color: #5466f9 !important;
    border-right-color: #5466f9 !important
}

.swal2-file,
.swal2-input,
.swal2-textarea {
    border: 1px solid var(--bs-input-border)
}

.swal2-file:focus,
.swal2-input:focus,
.swal2-textarea:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: var(--bs-input-focus-border)
}

.alertify .ajs-header {
    font-weight: 500;
    font-size: 16px;
    position: relative;
    color: #1a1823
}

.alertify .ajs-dialog {
    -webkit-box-shadow: 0 2px 3px rgba(52, 58, 64, .06);
    box-shadow: 0 2px 3px rgba(52, 58, 64, .06);
    background-color: var(--bs-modal-bg);
    border-radius: .4rem;
    overflow: hidden
}

.alertify .ajs-dialog .ajs-footer,
.alertify .ajs-dialog .ajs-header {
    background-color: var(--bs-modal-bg)
}

.alertify .ajs-body {
    color: #1a1823
}

.alertify .ajs-footer .ajs-buttons .ajs-button {
    font-size: 13px;
    color: #1a1823;
    font-weight: 500
}

.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    color: #5466f9
}

.alertify .ajs-footer .ajs-buttons.ajs-primary {
    text-align: right
}

.alertify .ajs-body .ajs-content {
    padding: 16px 0
}

.alertify .ajs-body .ajs-content .ajs-input:focus-visible {
    outline: 0
}

.alertify .ajs-body .ajs-content .ajs-input {
    display: block;
    width: 100%;
    padding: .47rem .75rem;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-input-bg);
    border: 1px solid var(--bs-input-border);
    border-radius: .25rem
}

.alertify .ajs-commands {
    top: 16px;
    right: 16px;
    left: auto;
    margin: 0
}

.alertify .ajs-commands button {
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat !important;
    opacity: .5
}

.alertify-notifier .ajs-message {
    background-color: #5466f9;
    border-color: #5466f9;
    color: #fff;
    text-shadow: none !important;
    border-radius: 4px
}

.alertify-notifier .ajs-message.ajs-success {
    background-color: #6dcba3;
    border-color: #6dcba3
}

.alertify-notifier .ajs-message.ajs-error {
    background-color: #e1665d;
    border-color: #e1665d
}

.alertify-notifier .ajs-message.ajs-warning {
    background-color: #f7cc53;
    border-color: #f7cc53
}

.alertify-notifier.ajs-right {
    right: 10px;
    left: auto
}

.alertify-notifier.ajs-right .ajs-message {
    right: -320px;
    left: auto
}

.alertify-notifier.ajs-right .ajs-message.ajs-visible {
    right: 290px;
    left: auto
}

.alertify-notifier.ajs-left {
    left: 10px;
    right: auto
}

.alertify-notifier.ajs-left .ajs-message {
    left: -300px;
    right: auto
}

.alertify-notifier.ajs-left .ajs-message.ajs-visible {
    left: 0;
    right: auto
}

.swiper-arrow .swiper-button-next,
.swiper-arrow .swiper-button-prev {
    height: 30px;
    width: 30px;
    margin: auto 0;
    color: #fff !important;
    background-color: #897dd5
}

.swiper-arrow .swiper-button-next::after,
.swiper-arrow .swiper-button-prev::after {
    font-size: 18px
}

.swiper-arrow .swiper-button-prev {
    position: absolute;
    left: 0
}

.swiper-arrow .swiper-button-next {
    position: absolute;
    right: 0
}

.swiper-arrow.arrow-sm .swiper-button-next,
.swiper-arrow.arrow-sm .swiper-button-prev {
    height: 24px;
    width: 24px
}

.swiper-arrow.arrow-sm .swiper-button-next::after,
.swiper-arrow.arrow-sm .swiper-button-prev::after {
    font-size: 12px
}

.swiper-pagination {
    -webkit-transform: translate3d(0, 0, 0) translateX(-50%);
    transform: translate3d(0, 0, 0) translateX(-50%);
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto !important;
    padding: 4px 4px;
    left: 50% !important;
    background-color: var(--bs-card-bg);
    border-radius: 10px;
    right: auto !important
}

.swiper-pagination-bullet {
    background-color: var(--bs-card-bg);
    opacity: 1;
    border: 1px solid #5466f9
}

.swiper-pagination-bullet-active {
    background-color: #5466f9;
    opacity: 1;
    border: 1px solid #5466f9
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: 10px;
    right: auto
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto
}

.progress-swiper .swiper-pagination-progressbar {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0;
    background-color: var(--bs-progress-bg)
}

.progress-swiper .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background-color: #5466f9
}

.choices__inner {
    padding: .25rem 2.5rem .25rem .5rem;
    background-color: var(--bs-input-bg);
    vertical-align: middle;
    border-radius: .25rem;
    border: 1px solid var(--bs-input-border) !important;
    min-height: 38px
}

.choices__heading {
    border-bottom: 1px solid var(--bs-input-border)
}

.is-focused .choices__inner,
.is-open .choices__inner {
    border-color: var(--bs-input-border)
}

.choices__list--dropdown {
    border-color: var(--bs-input-border) !important
}

.choices__list--dropdown {
    border: 1px solid var(--bs-border-color) !important;
    -webkit-box-shadow: 0 2px 3px rgba(52, 58, 64, .06);
    box-shadow: 0 2px 3px rgba(52, 58, 64, .06)
}

.choices[data-type*=select-one] .choices__input {
    background-color: var(--bs-input-bg) !important;
    border-color: var(--bs-input-border)
}

.choices[data-type*=select-one] .choices__inner {
    padding-bottom: .25rem;
    background-color: var(--bs-input-bg) !important
}

.choices[data-type*=select-one] .choices__button {
    right: 0;
    left: auto;
    margin-right: 25px;
    margin-left: 0
}

.choices[data-type*=select-one]:after {
    border-color: var(--bs-dark) transparent transparent;
    right: 11.5px;
    left: auto
}

.choices[data-type*=select-one].is-open:after {
    border-color: transparent transparent var(--bs-dark)
}

.choices__input {
    background-color: var(--bs-input-bg);
    margin-bottom: 0;
    color: var(--bs-gray-600)
}

.choices__list--multiple {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.choices__list--multiple .choices__item {
    background-color: rgba(var(--bs-primary-rgb), 1);
    border-color: rgba(var(--bs-primary-rgb), 1);
    margin-bottom: 0;
    margin-right: 7px;
    font-weight: 400
}

.choices__list--multiple .choices__item.is-highlighted {
    background-color: rgba(var(--bs-primary-rgb), 1);
    border: 1px solid rgba(var(--bs-primary-rgb), 1)
}

.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
    background-color: var(--bs-light)
}

.is-disabled .choices__list--multiple .choices__item {
    background-color: #969ba5;
    border-color: #969ba5
}

.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
    border-left: 1px solid rgba(255, 255, 255, .5);
    margin: 0 -4px 0 8px;
    border-right: 0
}

.choices__list--dropdown {
    background-color: var(--bs-card-bg);
    border: 1px solid var(--bs-input-border)
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
    background-color: var(--bs-gray-300)
}

input[switch] {
    display: none
}

input[switch]+label {
    font-size: 1em;
    line-height: 1;
    width: 56px;
    height: 24px;
    background-color: #e2e5e8;
    background-image: none;
    border-radius: 2rem;
    padding: .16667rem;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    position: relative;
    font-weight: 500;
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out
}

input[switch]+label:before {
    color: #343a40;
    content: attr(data-off-label);
    display: block;
    font-family: inherit;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    position: absolute;
    right: 1px;
    margin: 3px;
    top: -2px;
    text-align: center;
    min-width: 1.66667rem;
    overflow: hidden;
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out
}

input[switch]+label:after {
    content: "";
    position: absolute;
    left: 3px;
    background-color: #f4f5f6;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 2rem;
    height: 20px;
    width: 20px;
    top: 2px;
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out
}

input[switch]:checked+label {
    background-color: #5466f9
}

input[switch]:checked+label {
    background-color: #5466f9
}

input[switch]:checked+label:before {
    color: #fff;
    content: attr(data-on-label);
    right: auto;
    left: 3px
}

input[switch]:checked+label:after {
    left: 33px;
    background-color: #f4f5f6
}

input[switch=bool]+label {
    background-color: #e1665d
}

input[switch=bool]+label:before,
input[switch=bool]:checked+label:before,
input[switch=default]:checked+label:before {
    color: #fff
}

input[switch=bool]:checked+label {
    background-color: #6dcba3
}

input[switch=default]:checked+label {
    background-color: #a2a2a2
}

input[switch=primary]:checked+label {
    background-color: #5466f9
}

input[switch=success]:checked+label {
    background-color: #6dcba3
}

input[switch=info]:checked+label {
    background-color: #5fd0f3
}

input[switch=warning]:checked+label {
    background-color: #f7cc53
}

input[switch=danger]:checked+label {
    background-color: #e1665d
}

input[switch=dark]:checked+label {
    background-color: #343a40
}

.square-switch {
    margin-right: 7px
}

.square-switch input[switch]+label,
.square-switch input[switch]+label:after {
    border-radius: 4px
}

.table-bordered {
    border: 1px solid var(--bs-border-color)
}

.gridjs-container {
    color: var(--bs-table-color)
}

.gridjs-wrapper {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid var(--bs-border-color)
}

.gridjs-wrapper::-webkit-scrollbar {
    -webkit-appearance: none
}

.gridjs-wrapper::-webkit-scrollbar:vertical {
    width: 12px
}

.gridjs-wrapper::-webkit-scrollbar:horizontal {
    height: 12px
}

.gridjs-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(52, 58, 64, .075);
    border-radius: 10px;
    border: 2px solid var(--bs-card-bg)
}

.gridjs-wrapper::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--bs-card-bg)
}

.gridjs-table {
    width: 100%
}

td.gridjs-td,
th.gridjs-th {
    border: 1px solid var(--bs-border-color);
    padding: .75rem .75rem;
    background-color: var(--bs-dropdown-bg)
}

th.gridjs-th {
    border-top: 0;
    color: var(--bs-table-color);
    background-color: var(--bs-table-head-bg)
}

th.gridjs-th-sort:focus,
th.gridjs-th-sort:hover {
    background-color: var(--bs-table-head-bg)
}

.gridjs-footer {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid var(--bs-border-color);
    border-top: 0;
    background-color: var(--bs-table-head-bg)
}

.gridjs-pagination {
    color: #959396
}

.gridjs-pagination .gridjs-pages button {
    border: none !important;
    border-radius: 30px !important;
    margin: 0 5px;
    border: none;
    min-width: 32px;
    height: 32px;
    padding: 0;
    text-align: center;
    line-height: 32px;
    background-color: transparent;
    color: var(--bs-body-color)
}

.gridjs-pagination .gridjs-pages button:disabled,
.gridjs-pagination .gridjs-pages button:hover:disabled,
.gridjs-pagination .gridjs-pages button[disabled] {
    color: #adb5bd;
    background-color: transparent
}

.gridjs-pagination .gridjs-pages button:hover {
    background-color: var(--bs-pagination-hover-bg);
    color: #493ab3
}

.gridjs-pagination .gridjs-pages button:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.gridjs-pagination .gridjs-pages button:first-child:hover,
.gridjs-pagination .gridjs-pages button:last-child:hover {
    background-color: transparent
}

.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
    background-color: #5466f9;
    color: #fff;
    border-color: #5466f9
}

input.gridjs-input {
    border-color: var(--bs-input-border);
    background-color: var(--bs-input-bg);
    color: var(--bs-body-color)
}

input.gridjs-input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: var(--bs-input-focus-border);
    background-color: var(--bs-input-bg)
}

.pcr-app {
    background: var(--bs-card-bg)
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-preview {
    margin-right: .75em;
    margin-left: 0
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-chooser,
.pcr-app[data-theme=classic] .pcr-selection .pcr-color-opacity {
    margin-left: .75em;
    margin-right: 0
}

.flatpickr-input[readonly] {
    background-color: var(--bs-input-bg)
}

.flatpickr-months,
.flatpickr-weekdays {
    background-color: #5466f9
}

span.flatpickr-weekday {
    color: #fff;
    font-weight: 500
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    background-color: transparent !important
}

.flatpickr-am-pm:focus,
.flatpickr-am-pm:hover,
.numInput:focus,
.numInput:hover,
.numInputWrapper:focus,
.numInputWrapper:hover {
    background-color: transparent !important
}

.flatpickr-weekdays {
    height: 36px !important;
    border-bottom: 1px solid var(--bs-border-color)
}

.flatpickr-day {
    color: var(--bs-dark) !important
}

.flatpickr-day:focus,
.flatpickr-day:hover {
    background-color: rgba(248, 247, 252, .7) !important
}

.flatpickr-day.today {
    border-color: #5466f9 !important;
    background-color: rgba(119, 106, 207, .1)
}

.flatpickr-day.today:focus,
.flatpickr-day.today:hover {
    color: var(--bs-dark) !important;
    background-color: rgba(119, 106, 207, .15) !important
}

.flatpickr-day.selected {
    background-color: #5466f9 !important;
    border-color: #5466f9 !important;
    color: #fff !important
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.nextMonthDay,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.prevMonthDay {
    color: rgba(var(--bs-dark), .3) !important
}

.flatpickr-day.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.today.inRange,
.flatpickr-day:focus,
.flatpickr-day:hover {
    background-color: var(--bs-light) !important;
    border-color: var(--bs-light) !important
}

.flatpickr-day.inRange {
    -webkit-box-shadow: -5px 0 0 #f8f7fc, 5px 0 0 #f8f7fc;
    box-shadow: -5px 0 0 #f8f7fc, 5px 0 0 #f8f7fc
}

.flatpickr-months .flatpickr-month,
.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
    color: rgba(255, 255, 255, .9) !important;
    fill: rgba(255, 255, 255, .9) !important
}

.flatpickr-monthDropdown-month {
    color: rgba(0, 0, 0, .8)
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
    color: rgba(255, 255, 255, .9) !important
}

.flatpickr-time .flatpickr-am-pm,
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time input {
    color: var(--bs-dark) !important
}

.flatpickr-calendar {
    background-color: var(--bs-dropdown-bg) !important;
    -webkit-box-shadow: 1px 0 0 var(--bs-border-color), -1px 0 0 var(--bs-border-color), 0 1px 0 var(--bs-border-color), 0 -1px 0 var(--bs-border-color), 0 3px 13px rgba(0, 0, 0, .08) !important;
    box-shadow: 1px 0 0 var(--bs-border-color), -1px 0 0 var(--bs-border-color), 0 1px 0 var(--bs-border-color), 0 -1px 0 var(--bs-border-color), 0 3px 13px rgba(0, 0, 0, .08) !important
}

.flatpickr-calendar.hasTime .flatpickr-time {
    border-top: 1px solid var(--bs-border-color) !important
}

.flatpickr-months {
    border-radius: 5px 5px 0 0
}

.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg {
    fill: rgba(255, 255, 255, .9) !important
}

.ck-editor__editable {
    min-height: 250px !important
}

.ck.ck-reset_all,
.ck.ck-reset_all * {
    color: var(--bs-gray-700) !important
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: var(--bs-input-border) !important
}

.ck.ck-toolbar {
    background-color: var(--bs-light) !important;
    border-color: var(--bs-input-border) !important
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
    background-color: var(--bs-input-border) !important
}

.ck.ck-tooltip .ck-tooltip__text {
    background-color: #343a40 !important;
    color: #f8f7fc !important
}

.ck.ck-editor__main>.ck-editor__editable,
.ck.ck-list {
    background-color: var(--bs-card-bg) !important
}

.ck.ck-dropdown__panel {
    border-color: var(--bs-border-color) !important
}

.ck.ck-button.ck-on,
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button.ck-on,
a.ck.ck-button:not(.ck-disabled):hover {
    background-color: var(--bs-light) !important
}

.ql-editor {
    text-align: left
}

.ql-editor ol,
.ql-editor ul {
    padding-left: 1.5em;
    padding-right: 0
}

.ql-editor li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: .3em;
    text-align: right
}

.ql-container {
    font-family: "Noto Sans", sans-serif
}

.ql-container.ql-snow {
    border-color: var(--bs-input-border)
}

.ql-bubble {
    border: 1px solid var(--bs-input-border);
    border-radius: .25rem
}

.ql-toolbar {
    font-family: "Noto Sans", sans-serif !important
}

.ql-toolbar span {
    outline: 0 !important;
    color: var(--bs-dropdown-link-color)
}

.ql-toolbar span:hover {
    color: #5466f9 !important
}

.ql-toolbar.ql-snow {
    border-color: var(--bs-input-border)
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: transparent
}

.ql-toolbar.ql-snow .ql-picker-options {
    -webkit-box-shadow: 0 2px 3px rgba(52, 58, 64, .06);
    box-shadow: 0 2px 3px rgba(52, 58, 64, .06);
    border-radius: .25rem
}

.ql-snow .ql-script,
.ql-snow .ql-strike svg,
.ql-snow .ql-stroke {
    stroke: var(--bs-dropdown-link-color)
}

.ql-snow .ql-fill {
    fill: var(--bs-dropdown-link-color)
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: 0;
    left: auto
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: var(--bs-dropdown-link-color)
}

.ql-snow .ql-picker-options {
    background-color: var(--bs-dropdown-bg);
    border-color: var(--bs-border-color) !important
}

.dropzone {
    min-height: 230px;
    border: 2px dashed var(--bs-gray-400);
    background: var(--bs-card-bg);
    border-radius: 6px
}

.dropzone .dz-message {
    font-size: 24px;
    width: 100%
}

.wizard-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.wizard-nav .wizard-list-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center
}

.wizard-nav .wizard-list-item .list-item {
    width: 100%;
    font-size: 14px;
    position: relative
}

.wizard-nav .step-icon {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 54px;
    border: 1px solid rgba(119, 106, 207, .2);
    color: #5466f9;
    text-align: center;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    font-size: 20px
}

@media (max-width:575.98px) {
    .wizard-nav .step-icon {
        width: 40px;
        height: 40px;
        line-height: 38px
    }
}

.wizard-nav .step-title {
    margin-left: 6px
}

.wizard-nav .wizard-list-item:last-child .list-item::after {
    display: none
}

.wizard-nav .wizard-list-item .list-item.done .step-icon {
    background-color: #5466f9;
    color: #fff
}

.wizard-nav .wizard-list-item .list-item.done .uil:before {
    content: "\e9c3"
}

.wizard-nav .list-item {
    font-size: 14px;
    position: relative
}

@media (max-width:575.98px) {
    .wizard-nav .list-item {
        padding: .5rem
    }
}

.wizard-nav .list-item::after {
    content: "";
    position: absolute;
    width: 75%;
    height: 2px;
    background-color: var(--bs-border-color);
    left: 62%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

@media (max-width:575.98px) {
    .wizard-nav .list-item::after {
        display: none
    }
}

.wizard-nav .list-item.active {
    color: #1a1823;
    background-color: transparent
}

.wizard-nav .list-item.active .step-icon {
    background-color: rgba(119, 106, 207, .2);
    color: #5466f9;
    border-color: rgba(119, 106, 207, .2)
}

.wizard-tab {
    display: none
}

.apex-charts {
    min-height: 10px !important
}

.apex-charts text {
    font-family: "Noto Sans", sans-serif !important
}

.apex-charts .apexcharts-canvas {
    margin: 0 auto
}

.apexcharts-tooltip-text,
.apexcharts-tooltip-title {
    font-family: "Noto Sans", sans-serif !important
}

.apexcharts-tooltip {
    border: 1px solid var(--bs-border-color) !important;
    background-color: var(--bs-card-bg) !important;
    -webkit-box-shadow: 0 2px 3px rgba(52, 58, 64, .06) !important;
    box-shadow: 0 2px 3px rgba(52, 58, 64, .06) !important
}

.apexcharts-tooltip * {
    font-family: "Noto Sans", sans-serif !important;
    color: #959396 !important
}

.apexcharts-tooltip .apexcharts-tooltip-title {
    background-color: rgba(var(--bs-light-rgb), .75) !important;
    border-bottom: 1px solid var(--bs-border-color) !important
}

.apexcharts-tooltip.apexcharts-theme-dark * {
    color: #fff !important
}

.apexcharts-legend-series {
    font-weight: 500
}

.apexcharts-gridline {
    pointer-events: none;
    stroke: rgba(var(--bs-light-rgb), .75)
}

.apexcharts-legend-text {
    color: #959396 !important;
    font-family: "Noto Sans", sans-serif !important;
    font-size: 13px !important
}

.apexcharts-pie-label {
    fill: #fff !important
}

.apexcharts-subtitle-text,
.apexcharts-title-text {
    fill: #adb5bd
}

.apexcharts-xaxis text,
.apexcharts-yaxis text {
    font-family: "Noto Sans", sans-serif !important;
    fill: #adb5bd
}

.apexcharts-yaxis-title {
    font-weight: 500
}

#dynamicloadedchart-wrap {
    margin: 0 auto;
    max-width: 800px;
    position: relative
}

.chart-box {
    padding-left: 0
}

#chart-quarter,
#chart-year {
    width: 96%;
    max-width: 48%;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 0;
    padding-top: 20px;
    background: var(--bs-card-bg);
    border: 1px solid var(--bs-border-color)
}

#chart-year {
    float: left;
    position: relative;
    -webkit-transition: 1s ease transform;
    transition: 1s ease transform;
    z-index: 3
}

#chart-year.chart-quarter-activated {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: 1s ease transform;
    transition: 1s ease transform
}

#chart-quarter {
    float: left;
    position: relative;
    z-index: -2;
    -webkit-transition: 1s ease transform;
    transition: 1s ease transform
}

#chart-quarter.active {
    -webkit-transition: 1.1s ease-in-out transform;
    transition: 1.1s ease-in-out transform;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    z-index: 1
}

@media screen and (min-width:480px) {
    #chart-year {
        -webkit-transform: translateX(50%);
        transform: translateX(50%)
    }
    #chart-quarter {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }
}

.apexcharts-radar-series line,
.apexcharts-radar-series polygon {
    stroke: var(--bs-border-color)
}

.apexcharts-pie circle,
.apexcharts-pie line {
    stroke: var(--bs-border-color)
}

.apexcharts-pie text {
    fill: var(--bs-body-color)
}

.glightbox-clean .gslide-title {
    font-family: "Nunito Sans", sans-serif;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 18px
}

.glightbox-clean .gslide-desc {
    font-family: "Noto Sans", sans-serif;
    font-size: 14px
}

.glightbox-clean .gclose {
    border: 2px solid #fff;
    border-radius: 7px;
    right: 10px;
    left: auto
}

@media (min-width:992px) {
    .glightbox-clean .gclose {
        right: 20px;
        left: auto
    }
}

.glightbox-clean .gclose svg {
    width: 12px
}

.gmaps,
.gmaps-panaroma {
    height: 300px;
    background: #f8f9fa;
    border-radius: 3px
}

.gmaps-overlay {
    display: block;
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    background: #5466f9;
    border-radius: 4px;
    padding: 10px 20px
}

.gmaps-overlay_arrow {
    left: 50%;
    margin-left: -16px;
    width: 0;
    height: 0;
    position: absolute
}

.gmaps-overlay_arrow.above {
    bottom: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid #5466f9
}

.gmaps-overlay_arrow.below {
    top: -15px;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid #5466f9
}

.jvm-tooltip {
    border-radius: 3px;
    background-color: #5466f9;
    font-family: "Noto Sans", sans-serif;
    -webkit-box-shadow: 0 5px 6px rgba(52, 58, 64, .1);
    box-shadow: 0 5px 6px rgba(52, 58, 64, .1);
    padding: 3px 5px
}

.leaflet-map {
    height: 300px
}

.leaflet-map.leaflet-container {
    z-index: 99
}

.auth-bg-basic {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat
}

.auth-bg-basic .bg-overlay {
    opacity: .85
}

@media (min-width:768px) {
    .auth-bg-basic {
        height: 100vh
    }
}

.auth-pass-inputgroup input[type=text]+.btn .mdi-eye-outline:before {
    content: "\f06d1"
}

.auth-bg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%)
}

.auth-bg .bg-overlay {
    opacity: .85
}

@media (min-width:768px) {
    .auth-bg {
        height: 100vh
    }
}

@media (max-width:768px) {
    .auth-bg {
        -webkit-clip-path: none;
        clip-path: none
    }
}

.home-btn {
    position: fixed;
    top: 15px;
    right: 25px
}

.authentication-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.authentication-bg .auth-logo .logo {
    margin: 0 auto
}

.authentication-bg .auth-logo .auth-logo-light {
    display: none;
    margin: 0 auto
}

.authentication-bg .auth-logo .auth-logo-dark {
    margin: 0 auto
}

.authentication-bg .bg-overlay {
    opacity: .8
}

.authentication-bg .auth-cover-card .authentication-bg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.authentication-bg .auth-cover-card .bg-overlay {
    opacity: .6
}

.authentication-bg .auth-cover-card .form-floating label {
    color: rgba(255, 255, 255, .5)
}

.authentication-bg .auth-cover-card .form-floating .form-control {
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
    color: #fff
}

.authentication-bg .auth-cover-card .form-floating .form-floating-icon {
    color: rgba(255, 255, 255, .5)
}

.form-floating-custom {
    position: relative
}

.form-floating-custom>label {
    left: 48px
}

.form-floating-custom>.form-control,
.form-floating-custom>.form-select {
    padding-left: 60px
}

.form-floating-custom .form-floating-icon {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 20px;
    width: 56px;
    color: var(--bs-text-muted)
}

.error-font {
    font-size: 9rem;
    text-shadow: 4px 4px 6px rgba(33, 37, 41, .26)
}

.mover-animation {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate
}

@-webkit-keyframes mover {
    0% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }
    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }
}

@keyframes mover {
    0% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }
    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }
}

.auth-img {
    background-image: url(https://images.unsplash.com/photo-1593062096033-9a26b09da705?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80);
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%
}

@media (max-width:991.98px) {
    .auth-img {
        height: 250px
    }
}

body[data-layout-mode=dark] body[data-layout-mode=dark] .authentication-bg .bg-overlay {
    opacity: .1
}

body[data-layout-mode=dark] .authentication-bg .auth-logo .auth-logo-dark {
    display: none
}

body[data-layout-mode=dark] .authentication-bg .auth-logo .auth-logo-light {
    display: block
}

.task-board {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.task-board::-webkit-scrollbar {
    -webkit-appearance: none
}

.task-board::-webkit-scrollbar:vertical {
    width: 12px
}

.task-board::-webkit-scrollbar:horizontal {
    height: 10px
}

.task-board::-webkit-scrollbar-thumb {
    background-color: rgba(52, 58, 64, .2);
    border-radius: 10px;
    border: 2px solid #fff
}

.task-board::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff
}

.tasks {
    min-height: 300px
}

.task-list {
    min-width: 280px;
    white-space: normal
}

@media (min-width:576px) {
    .task-list {
        width: 380px;
        min-width: 384px
    }
}

.task-list:not(:last-child) {
    margin-right: 20px
}

.task-list .tasklist-content {
    max-height: calc(100vh - 350px)
}

.task-list .task-box {
    border: 1px solid var(--bs-gray-300)
}

.task-list .task-box:last-child {
    margin-bottom: 0
}

.gu-transit {
    border: 1px dashed var(--bs-gray-600) !important;
    background-color: var(--bs-gray-200) !important
}

.search-box .search-icon {
    font-size: 16px;
    position: absolute;
    left: 13px;
    color: var(--bs-text-muted);
    line-height: 40px
}

.categories-list {
    padding: 4px 0
}

.categories-list li a {
    display: block;
    padding: 8px 12px;
    color: #1a1823;
    font-weight: 500
}

.categories-list li.active a {
    color: #5466f9
}

.categories-list li ul {
    padding-left: 14px
}

.categories-list li ul li a {
    padding: 4px 12px;
    color: var(--bs-text-muted);
    font-size: 13px;
    font-weight: 400
}

.email-leftbar {
    width: 306px;
    float: left
}

.email-rightbar {
    margin-left: 324px
}

.chat-user-box p.user-title {
    color: var(--bs-dark);
    font-weight: 500
}

.chat-user-box p {
    font-size: 14px
}

.unread {
    background-color: var(--bs-gray-200);
    font-weight: 500;
    color: #292d32
}

.message-list:hover {
    background-color: var(--bs-gray-200)
}

@media (max-width:767px) {
    .email-leftbar {
        float: none;
        width: 100%
    }
    .email-rightbar {
        margin: 0
    }
}

.mail-list a {
    display: block;
    color: var(--bs-gray-600);
    line-height: 24px;
    padding: 8px 18px
}

.mail-list a.active {
    color: #5466f9;
    font-weight: 500
}

.mail-list a.active h5 {
    color: #5466f9
}

.message-list {
    display: block;
    padding-left: 0
}

.message-list .list {
    position: relative;
    display: block;
    height: 50px;
    cursor: default;
    -webkit-transition-duration: .3s;
    transition-duration: .3s
}

.message-list .list a {
    color: #959396
}

.message-list .list.selected {
    background-color: rgba(239, 240, 242, .6)
}

.message-list .list .col-mail {
    float: left;
    position: relative
}

.message-list .list .col-mail-1 {
    width: 320px
}

.message-list .list .col-mail-1 .checkbox-wrapper-mail,
.message-list .list .col-mail-1 .dot,
.message-list .list .col-mail-1 .star-toggle {
    display: block;
    float: left
}

.message-list .list .col-mail-1 .dot {
    border: 4px solid transparent;
    border-radius: 100px;
    margin: 22px 26px 0;
    height: 0;
    width: 0;
    line-height: 0;
    font-size: 0
}

.message-list .list .col-mail-1 .checkbox-wrapper-mail {
    margin: 15px 10px 0 20px
}

.message-list .list .col-mail-1 .star-toggle {
    margin-top: 18px;
    margin-left: 5px
}

.message-list .list .col-mail-1 .title {
    position: absolute;
    top: 0;
    left: 110px;
    right: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0;
    margin-top: 8px
}

.message-list .list .col-mail-2 {
    position: absolute;
    top: 14px;
    left: 320px;
    right: 0;
    bottom: 0
}

.message-list .list .col-mail-2 .date,
.message-list .list .col-mail-2 .subject {
    position: absolute;
    top: 0
}

.message-list .list .col-mail-2 .subject {
    left: 0;
    right: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500
}

.message-list .list .col-mail-2 .date {
    right: 10px;
    width: 170px;
    padding-left: 80px
}

.message-list .list.active,
.message-list .list.active:hover {
    -webkit-box-shadow: inset 3px 0 0 #5466f9;
    box-shadow: inset 3px 0 0 #5466f9
}

.message-list .checkbox-wrapper-mail {
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    display: inline-block;
    -webkit-box-shadow: inset 0 0 0 1px var(--bs-gray-400);
    box-shadow: inset 0 0 0 1px var(--bs-gray-400);
    border-radius: 1px
}

.message-list .checkbox-wrapper-mail input {
    opacity: 0;
    cursor: pointer
}

.message-list .checkbox-wrapper-mail input:checked~label {
    opacity: 1
}

.message-list .checkbox-wrapper-mail label {
    position: absolute;
    height: 20px;
    width: 20px;
    left: 0;
    cursor: pointer;
    opacity: 0;
    margin-bottom: 0;
    -webkit-transition-duration: 50ms;
    transition-duration: 50ms;
    top: 17px
}

.message-list .checkbox-wrapper-mail label:before {
    content: "\f012c";
    font-family: "Material Design Icons";
    top: 0;
    height: 20px;
    color: var(--bs-dark);
    width: 20px;
    position: absolute;
    margin-top: -16px;
    left: 4px;
    font-size: 13px
}

.email-search .form-control {
    border: 0;
    padding: .6rem .75rem
}

.email-search span {
    position: absolute;
    z-index: 10;
    line-height: 38px;
    right: 13px;
    top: 2px;
    color: #959396
}

@media (max-width:575.98px) {
    .message-list .list .col-mail-1 {
        width: 200px
    }
}

@media (min-width:1200px) {
    .chat-leftsidebar {
        min-width: 380px
    }
}

@media (min-width:992px) and (max-width:1199.98px) {
    .chat-leftsidebar {
        min-width: 290px
    }
}

.chat-noti-dropdown.active:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #e1665d;
    border-radius: 50%;
    right: 0
}

.chat-noti-dropdown .btn {
    padding: 6px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    color: var(--bs-gray-600)
}

.chat-user-status {
    position: relative
}

.chat-user-status .status {
    width: 12px;
    height: 12px;
    background-color: #6dcba3;
    border-radius: 50%;
    border: 2px solid #fff;
    position: absolute;
    left: 60px;
    right: 0;
    margin: 0 auto;
    bottom: 5px
}

.chat-search-box .form-control {
    border: 0
}

.chat-message-list {
    height: calc(100vh - 280px)
}

@media (min-width:992px) {
    .chat-message-list {
        height: calc(100vh - 335px)
    }
}

@media (min-width:992px) {
    .chat-rightsidebar {
        height: calc(100vh - 690px)
    }
}

.chat-list {
    margin: 0
}

.chat-list li.active a {
    background-color: rgba(119, 106, 207, .075);
    border-color: transparent
}

.chat-list li a {
    position: relative;
    display: block;
    color: var(--bs-text-muted);
    -webkit-transition: all .4s;
    transition: all .4s;
    padding: 12px;
    border: 1px solid var(--bs-border-color);
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    margin: 12px 0
}

.chat-list li a:hover {
    background-color: rgba(119, 106, 207, .075);
    border-color: transparent
}

.chat-list li .user-img {
    position: relative
}

.chat-list li .user-img .user-status {
    width: 10px;
    height: 10px;
    background-color: #adb5bd;
    border-radius: 50%;
    border: 2px solid var(--bs-card-bg);
    position: absolute;
    left: 0;
    bottom: 0
}

.chat-list li .user-img.online .user-status {
    background-color: #6dcba3
}

.chat-list li .user-img.away .user-status {
    background-color: #f7cc53
}

.chat-list li.unread a {
    font-weight: 600;
    color: #343a40
}

.chat-list li .unread-message {
    position: absolute;
    display: inline-block;
    right: 16px;
    top: 33px
}

.group-list li a {
    border: 0
}

.user-chat-nav .dropdown .nav-btn {
    height: 36px;
    width: 36px;
    line-height: 36px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    font-size: 16px;
    border-radius: 50%;
    color: var(--bs-gray-600)
}

.contact-list {
    font-size: 12px;
    text-transform: uppercase;
    color: var(--bs-text-muted);
    font-weight: 600
}

.chat-conversation {
    height: calc(100vh - 300px)
}

@media (min-width:992px) {
    .chat-conversation {
        height: calc(100vh - 335px)
    }
}

.chat-conversation li {
    clear: both
}

.chat-conversation .chat-day-title {
    position: relative;
    text-align: center;
    margin-bottom: 24px;
    border: none
}

.chat-conversation .chat-day-title .title {
    display: inline-block;
    background-color: var(--bs-light);
    position: relative;
    z-index: 1;
    padding: 4px 16px;
    border-radius: 30px
}

.chat-conversation .chat-day-title:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    background-color: var(--bs-border-color);
    top: 16px
}

.chat-conversation .chat-day-title .badge {
    font-size: 12px
}

.chat-conversation .chat-avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%
}

.chat-conversation .conversation-list {
    margin-bottom: 45px;
    position: relative
}

@media (min-width:992px) {
    .chat-conversation .conversation-list {
        max-width: 85%
    }
}

.chat-conversation .conversation-list .ctext-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px
}

.chat-conversation .conversation-list .ctext-wrap-content {
    padding: 14px 20px;
    background-color: rgba(119, 106, 207, .1);
    border-radius: 0 8px 8px 8px;
    color: var(--bs-text-muted);
    position: relative;
    margin-left: 16px
}

.chat-conversation .conversation-list .ctext-wrap-content:before {
    content: "";
    position: absolute;
    border: 5px solid transparent;
    border-right-color: rgba(119, 106, 207, .1);
    border-top-color: rgba(119, 106, 207, .1);
    left: -10px;
    top: 0
}

.chat-conversation .conversation-list .conversation-name {
    margin-bottom: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 13px
}

.chat-conversation .conversation-list .conversation-name .time {
    font-size: 12px;
    position: absolute;
    left: -50px;
    top: 54px;
    bottom: 0;
    color: var(--bs-text-muted);
    font-weight: 400;
    -webkit-transition: all .4s;
    transition: all .4s
}

.chat-conversation .conversation-list .conversation-name .user-name {
    color: #343a40
}

.chat-conversation .conversation-list .dropdown .dropdown-toggle {
    font-size: 18px;
    padding: 4px;
    color: #959396
}

@media (max-width:575.98px) {
    .chat-conversation .conversation-list .dropdown .dropdown-toggle {
        display: none
    }
}

.chat-conversation .conversation-list .chat-time {
    font-size: 12px;
    margin-top: 4px;
    text-align: right;
    color: rgba(255, 255, 255, .5)
}

.chat-conversation .conversation-list .message-img {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px
}

.chat-conversation .conversation-list .message-img .message-img-list {
    position: relative
}

.chat-conversation .conversation-list .message-img img {
    max-width: 80px
}

.chat-conversation .right .conversation-list {
    float: right;
    text-align: right
}

.chat-conversation .right .conversation-list .ctext-wrap .ctext-wrap-content {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    background-color: #5466f9;
    text-align: right;
    border-radius: 8px 0 8px 8px;
    margin-left: 0;
    margin-right: 6px;
    color: #fff
}

.chat-conversation .right .conversation-list .ctext-wrap .ctext-wrap-content:before {
    border: 5px solid transparent;
    border-top-color: #5466f9;
    border-left-color: #5466f9;
    left: auto;
    right: -10px
}

.chat-conversation .right .conversation-list .ctext-wrap .conversation-name {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.chat-conversation .right .conversation-list .ctext-wrap .conversation-name .time {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-left: 0;
    margin-right: 8px;
    position: absolute;
    right: -50px;
    left: auto
}

.chat-conversation .right .conversation-list .ctext-wrap .conversation-name .user-name {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2
}

.chat-conversation .right .conversation-list .dropdown {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

.chat-conversation .right .conversation-list .chat-avatar {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3
}

.chat-input-section {
    border-top: 1px solid var(--bs-border-color)
}

.chat-input {
    background-color: var(--bs-light) !important;
    border-color: var(--bs-light) !important
}

@media (max-width:575.98px) {
    .chat-send {
        min-width: auto
    }
}

.countdownlist {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 24px
}

.countdownlist .countdownlist-item {
    width: 25%
}

.countdownlist .countdownlist-item:last-of-type .count-num::after {
    display: none
}

.countdownlist .count-title {
    font-size: 13px;
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
    color: rgba(52, 58, 64, .5);
    text-transform: uppercase
}

.countdownlist .count-num {
    background-color: var(--bs-card-bg);
    padding: 16px 8px;
    position: relative;
    border-radius: .25rem;
    -webkit-box-shadow: 0 2px 3px rgba(52, 58, 64, .06);
    box-shadow: 0 2px 3px rgba(52, 58, 64, .06);
    font-weight: 600;
    font-size: 32px
}

@media (max-width:575.98px) {
    .countdownlist .count-num {
        font-size: 18px
    }
}

.countdownlist .count-num::after {
    content: ":";
    font-size: 20px;
    position: absolute;
    right: -16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #343a40
}

.countdown-endtxt {
    font-size: 24px;
    font-weight: 600
}

.countdown-input-group {
    max-width: 350px
}

.wrapper {
    width: 95%;
    margin: 0 auto
}

.timeline-sec {
    padding: 80px 0 10px 0
}

.timeline-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-bottom: 100px;
    position: relative
}

.timeline-box {
    width: 33.33%;
    position: relative
}

.timeline-row:nth-child(2n+2) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.timeline-row:nth-child(2n+2)::after {
    right: auto;
    left: 0;
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
    transform: translate(-50%, -50%) rotate(90deg)
}

.timeline-row:nth-child(2n+2) .timeline-box {
    width: 30%
}

.timeline-row:nth-child(2n+2) .timeline-box::after {
    -webkit-transform: translate(50%, -50%) rotate(180deg);
    transform: translate(50%, -50%) rotate(180deg);
    left: -50%
}

.timeline-row:nth-child(2n+2) .timeline-box:nth-child(3)::after {
    display: none
}

.timeline-row:nth-child(2n+2) .timeline-box .timeline-content {
    width: 85%
}

.timeline-date .date {
    position: relative;
    padding-top: 20px
}

.timeline-box .timeline-date {
    position: relative;
    border: 2px solid var(--bs-border-color);
    width: 90px;
    height: 90px;
    border-radius: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--bs-card-bg);
    margin-top: -48px;
    z-index: 1
}

.timeline-box .timeline-content {
    background-color: var(--bs-gray-200);
    padding: 25px;
    width: 85%;
    margin-top: 30px;
    position: relative
}

.timeline-box .timeline-content::before {
    width: 0;
    height: 0;
    border-bottom: 15px solid var(--bs-gray-200);
    border-right: 17px solid transparent;
    position: absolute;
    top: -14px;
    left: 0;
    content: ""
}

.timeline-row:last-child {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    padding-bottom: 70px
}

.timeline-row:last-child::after {
    position: absolute;
    top: 0;
    left: 3%;
    width: 47%;
    height: 0;
    content: "";
    background-color: transparent;
    background-image: none;
    -webkit-transform: none;
    transform: none;
    z-index: 0;
    border-top: 2px dotted var(--bs-border-color)
}

.timeline-row:last-child .timeline-box {
    width: auto;
    z-index: 1
}

.timeline-row:last-child .timeline-box::after {
    display: none
}

.timeline-row:last-child .timeline-box .timeline-date {
    color: #fff;
    border: 0;
    margin-top: -46px
}

.timeline-row:nth-last-child(1):nth-child(even):after {
    right: 3%;
    left: initial
}

.timeline-row .horizontal-line {
    border-top: 2px dotted var(--bs-border-color);
    width: calc(100% - 100px);
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}

.timeline-row .verticle-line {
    margin: 0;
    padding: 0;
    width: 2px;
    border-right: 2px dotted var(--bs-border-color);
    height: calc(100% - 100px);
    position: absolute;
    top: 0;
    right: 0;
    margin: auto 0;
    bottom: 0
}

.timeline-row:nth-child(2n+2) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.timeline-row:nth-child(2n+2) .verticle-line {
    left: 0;
    right: inherit
}

.timeline-row:nth-child(2n+2) .corner {
    right: inherit;
    left: 0;
    border-left: 2px dotted var(--bs-border-color);
    border-right: 0;
    border-radius: 70px 0 0 0
}

.timeline-row:nth-child(2n+2) .corner.bottom {
    border-radius: 0 0 0 70px
}

.timeline-sec .local-solutions-txt {
    font-size: 24px
}

.corner {
    position: absolute;
    border-right: 2px dotted var(--bs-border-color);
    width: 50px;
    height: 50px;
    right: 0;
    border-top: 2px dotted var(--bs-border-color);
    top: 0;
    border-radius: 0 70px 0 0
}

.corner.bottom {
    bottom: -2px;
    top: inherit;
    border-radius: 0;
    border-top: 0;
    border-bottom: 2px dotted var(--bs-border-color);
    border-radius: 0 0 70px 0;
    right: 0
}

@media screen and (max-width:1024px) {
    .timeline-vertical .timeline-box .timeline-date {
        width: 70px;
        height: 70px
    }
    .timeline-row {
        padding-bottom: 0
    }
    .timeline-row .horizontal-line {
        display: none
    }
    .timeline-row .verticle-line {
        display: none
    }
    .timeline-row::after {
        display: none;
        width: 26px;
        height: 26px
    }
    .timeline-row:nth-child(2n+2) {
        padding-left: 0;
        -webkit-box-orient: unset;
        -webkit-box-direction: unset;
        -ms-flex-direction: unset;
        flex-direction: unset
    }
    .timeline-row:nth-child(2n+2)::after {
        display: none;
        width: 26px;
        height: 26px
    }
    .timeline-row:nth-child(2n+2) .timeline-box {
        width: 100%
    }
    .timeline-row:nth-child(2n+2) .timeline-box::after {
        -webkit-transform: translate(-50%, 100%) rotate(90deg);
        transform: translate(-50%, 100%) rotate(90deg);
        top: 50%;
        left: 50%
    }
    .timeline-row:nth-child(2n+2) .timeline-box .timeline-content {
        width: 41%;
        margin: -40px 0 0 0
    }
    .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+1) {
        text-align: right
    }
    .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+1) .timeline-content {
        display: inline-block;
        text-align: left
    }
    .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+1) .timeline-content::before {
        left: -16px;
        right: auto;
        border-bottom: 0 solid #f4f5f6;
        border-top: 15px solid #f4f5f6;
        top: 0;
        border-left: 15px solid transparent
    }
    .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+2) {
        text-align: left
    }
    .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+2) .timeline-content::before {
        left: auto;
        right: -16px;
        border-bottom: 0 solid #f4f5f6;
        border-top: 15px solid #f4f5f6;
        top: 0
    }
    .timeline-row:nth-child(2n+2) .timeline-box:nth-child(3)::after {
        display: block
    }
    .timeline-row:nth-child(2n+2) .verticle-line {
        left: 50%
    }
    .timeline-row:last-child {
        padding-bottom: 20px
    }
    .timeline-row:last-child .timeline-box .timeline-date {
        margin-top: 0;
        font-size: 13px;
        width: 66px;
        height: 66px
    }
    .corner {
        display: none
    }
    .timeline-box {
        width: 100%;
        padding-bottom: 30px
    }
    .timeline-box::after {
        -webkit-transform: translate(-50%, 100%) rotate(90deg);
        transform: translate(-50%, 100%) rotate(90deg);
        top: 50%;
        left: 50%;
        width: 26px;
        height: 26px
    }
    .timeline-box .timeline-date {
        margin-top: 0;
        margin: 0 auto;
        width: 80px;
        height: 80px
    }
    .timeline-box .timeline-date .date {
        padding-top: 13px
    }
    .timeline-box .timeline-content {
        width: 41%;
        margin: -40px 0 0 0
    }
    .timeline-box .timeline-content::before {
        left: auto;
        right: -16px;
        border-bottom: 0 solid #f4f5f6;
        border-top: 15px solid #f4f5f6;
        top: 0
    }
    .timeline-box:nth-child(2n+2) {
        text-align: right
    }
    .timeline-box:nth-child(2n+2) .timeline-content {
        display: inline-block;
        text-align: left
    }
    .timeline-box:nth-child(2n+2) .timeline-content::before {
        left: -16px;
        right: auto;
        border-bottom: 0 solid #f4f5f6;
        border-top: 15px solid #f4f5f6;
        top: 0;
        border-left: 15px solid transparent
    }
    .verticle-line {
        display: block !important;
        left: 50%;
        height: calc(100% - 0px) !important;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0)
    }
}

@media screen and (max-width:640px) {
    .timeline-box {
        text-align: right;
        padding-bottom: 20px
    }
    .timeline-box .timeline-date {
        margin: 0;
        width: 50px;
        height: 50px
    }
    .timeline-box .timeline-content {
        width: 70%;
        margin: -38px 0 0 0;
        text-align: left;
        font-size: 13px;
        display: inline-block
    }
    .timeline-box .timeline-content::before {
        left: -14px;
        right: 0;
        border-bottom: 0 solid #f4f5f6;
        border-top: 15px solid #f4f5f6;
        top: 0;
        border-top: 15px solid #f4f5f6;
        border-left: 15px solid transparent;
        border-right: 0 solid transparent
    }
    .timeline-box:nth-child(2n+2) .timeline-content::before {
        left: -14px
    }
    .timeline-box::after {
        width: 24px;
        height: 24px;
        left: 35px;
        left: 25px
    }
    .verticle-line {
        left: 25px
    }
    .timeline-row:nth-child(2n+2) .verticle-line {
        left: 25px
    }
    .timeline-row:nth-child(2n+2) .timeline-box .timeline-content {
        width: 70%;
        margin: -38px 0 0 0;
        text-align: left;
        font-size: 13px;
        display: inline-block
    }
    .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+2) {
        text-align: right
    }
    .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+2) .timeline-content::before {
        left: -14px;
        right: 0;
        border-bottom: 0 solid #f4f5f6;
        border-top: 15px solid #f4f5f6;
        top: 0;
        border-top: 15px solid #f4f5f6;
        border-left: 15px solid transparent;
        border-right: 0 solid transparent
    }
    .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+1) .timeline-content::before {
        left: -14px
    }
    .timeline-row:nth-child(2n+2) .timeline-box::after {
        left: 25px
    }
    .timeline-row:nth-child(2n+2)::after {
        width: 24px;
        height: 24px;
        left: 35px
    }
    .timeline-row:last-child {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
    .timeline-row:last-child .timeline-box {
        margin: 0 0 0 6px
    }
    .timeline-row:last-child .timeline-box .timeline-date {
        width: 50px;
        height: 50px
    }
    .timeline-row::after {
        width: 24px;
        height: 24px;
        left: 35px
    }
}

.timeline-vertical .timeline-row {
    padding-bottom: 0
}

.timeline-vertical .timeline-row .horizontal-line {
    display: none
}

.timeline-vertical .timeline-row .verticle-line {
    display: none
}

.timeline-vertical .timeline-row::after {
    display: none;
    width: 26px;
    height: 26px
}

.timeline-vertical .timeline-row:nth-child(2n+2) {
    padding-left: 0;
    -webkit-box-orient: unset;
    -webkit-box-direction: unset;
    -ms-flex-direction: unset;
    flex-direction: unset
}

.timeline-vertical .timeline-row:nth-child(2n+2)::after {
    display: none;
    width: 26px;
    height: 26px
}

.timeline-vertical .timeline-row:nth-child(2n+2) .timeline-box {
    width: 100%
}

.timeline-vertical .timeline-row:nth-child(2n+2) .timeline-box::after {
    -webkit-transform: translate(-50%, 100%) rotate(90deg);
    transform: translate(-50%, 100%) rotate(90deg);
    top: 50%;
    left: 50%
}

.timeline-vertical .timeline-row:nth-child(2n+2) .timeline-box .timeline-content {
    width: 41%;
    margin: -40px 0 0 0
}

.timeline-vertical .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+1) {
    text-align: right
}

.timeline-vertical .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+1) .timeline-content {
    display: inline-block;
    text-align: left
}

.timeline-vertical .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+1) .timeline-content::before {
    left: -16px;
    right: auto;
    border-bottom: 0 solid var(--bs-gray-200);
    border-top: 15px solid var(--bs-gray-200);
    top: 0;
    border-left: 15px solid transparent
}

.timeline-vertical .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+2) {
    text-align: left
}

.timeline-vertical .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+2) .timeline-content::before {
    left: auto;
    right: -16px;
    border-bottom: 0 solid var(--bs-gray-200);
    border-top: 15px solid var(--bs-gray-200);
    top: 0
}

.timeline-vertical .timeline-row:nth-child(2n+2) .timeline-box:nth-child(3)::after {
    display: block
}

.timeline-vertical .timeline-row:nth-child(2n+2) .verticle-line {
    left: 50%
}

.timeline-vertical .timeline-row:last-child {
    padding-bottom: 20px
}

.timeline-vertical .timeline-row:last-child .timeline-box .timeline-date {
    margin-top: 0;
    font-size: 13px;
    width: 66px;
    height: 66px
}

.timeline-vertical .corner {
    display: none
}

.timeline-vertical .timeline-box {
    width: 100%;
    padding-bottom: 30px
}

.timeline-vertical .timeline-box::after {
    -webkit-transform: translate(-50%, 100%) rotate(90deg);
    transform: translate(-50%, 100%) rotate(90deg);
    top: 50%;
    left: 50%;
    width: 26px;
    height: 26px
}

.timeline-vertical .timeline-box .timeline-date {
    margin-top: 0;
    margin: 0 auto;
    width: 85px;
    height: 85px
}

.timeline-vertical .timeline-box .timeline-date .date {
    padding-top: 16px
}

.timeline-vertical .timeline-box .timeline-content {
    width: 43%;
    margin: -40px 0 0 0
}

.timeline-vertical .timeline-box .timeline-content::before {
    left: auto;
    right: -16px;
    border-bottom: 0 solid var(--bs-gray-200);
    border-top: 15px solid var(--bs-gray-200);
    top: 0
}

.timeline-vertical .timeline-box:nth-child(2n+2) {
    text-align: right
}

.timeline-vertical .timeline-box:nth-child(2n+2) .timeline-content {
    display: inline-block;
    text-align: left
}

.timeline-vertical .timeline-box:nth-child(2n+2) .timeline-content::before {
    left: -16px;
    right: auto;
    border-bottom: 0 solid var(--bs-gray-200);
    border-top: 15px solid var(--bs-gray-200);
    top: 0;
    border-left: 15px solid transparent
}

.timeline-vertical .verticle-line {
    display: block !important;
    left: 50%;
    height: calc(100% - 0px) !important;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
}

@media screen and (max-width:640px) {
    .timeline-vertical .timeline-box {
        text-align: right;
        padding-bottom: 20px
    }
    .timeline-vertical .timeline-box .timeline-date {
        margin: 0;
        width: 50px;
        height: 50px
    }
    .timeline-vertical .timeline-box .timeline-date .date {
        padding-top: 12px
    }
    .timeline-vertical .timeline-box .timeline-content {
        width: 80%;
        margin: -38px 0 0 0;
        text-align: left;
        font-size: 13px;
        display: inline-block
    }
    .timeline-vertical .timeline-box .timeline-content::before {
        left: -14px;
        right: 0;
        border-bottom: 0 solid #f4f5f6;
        border-top: 15px solid #f4f5f6;
        top: 0;
        border-top: 15px solid #f4f5f6;
        border-left: 15px solid transparent;
        border-right: 0 solid transparent
    }
    .timeline-vertical .timeline-box:nth-child(2n+2) .timeline-content::before {
        left: -14px
    }
    .timeline-vertical .timeline-box::after {
        width: 24px;
        height: 24px;
        left: 35px;
        left: 25px
    }
    .timeline-vertical .verticle-line {
        left: 25px
    }
    .timeline-vertical .timeline-row:nth-child(2n+2) .verticle-line {
        left: 25px
    }
    .timeline-vertical .timeline-row:nth-child(2n+2) .timeline-box .timeline-content {
        width: 70%;
        margin: -38px 0 0 0;
        text-align: left;
        font-size: 13px;
        display: inline-block
    }
    .timeline-vertical .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+2) {
        text-align: right
    }
    .timeline-vertical .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+2) .timeline-content::before {
        left: -14px;
        right: 0;
        border-bottom: 0 solid #f4f5f6;
        border-top: 15px solid #f4f5f6;
        top: 0;
        border-top: 15px solid #f4f5f6;
        border-left: 15px solid transparent;
        border-right: 0 solid transparent
    }
    .timeline-vertical .timeline-row:nth-child(2n+2) .timeline-box:nth-child(2n+1) .timeline-content::before {
        left: -14px
    }
    .timeline-vertical .timeline-row:nth-child(2n+2) .timeline-box::after {
        left: 25px
    }
    .timeline-vertical .timeline-row:nth-child(2n+2)::after {
        width: 24px;
        height: 24px;
        left: 35px
    }
    .timeline-vertical .timeline-row:last-child {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
    .timeline-vertical .timeline-row:last-child .timeline-box {
        margin: 0 0 0 6px
    }
    .timeline-vertical .timeline-row:last-child .timeline-box .timeline-date {
        width: 50px;
        height: 50px
    }
    .timeline-vertical .timeline-row::after {
        width: 24px;
        height: 24px;
        left: 35px
    }
}

.search-box .form-control {
    border-radius: 30px;
    padding-left: 40px
}

.search-box .search-icon {
    font-size: 16px;
    position: absolute;
    left: 13px;
    top: 2px;
    color: #959396;
    line-height: 38px
}

.categories-list {
    padding: 4px 0
}

.categories-list li a {
    display: block;
    padding: 4px 12px;
    color: var(--bs-body-color)
}

.categories-list li.active a {
    color: #5466f9
}

.product-box {
    border: 1px solid var(--bs-border-color);
    -webkit-transition: all .4s;
    transition: all .4s;
    overflow: hidden;
    margin-top: 20px;
    cursor: pointer;
    position: relative
}

.product-box:hover {
    -webkit-box-shadow: 0 2px 3px rgba(52, 58, 64, .06);
    box-shadow: 0 2px 3px rgba(52, 58, 64, .06)
}

.product-box:hover .product-color {
    visibility: visible
}

.product-box .product-ribbon {
    position: absolute;
    background-color: #5466f9;
    color: #fff;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 36px;
    width: 70px;
    top: -2px;
    -webkit-transform: rotate(-90deg) translateX(-27%);
    transform: rotate(-90deg) translateX(-27%);
    font-weight: 500
}

.product-box .product-ribbon:after {
    content: "";
    border-top: 18px solid #5466f9;
    border-bottom: 18px solid #5466f9;
    border-right: 11px solid transparent;
    border-left: 11px solid transparent;
    position: absolute;
    left: -11px;
    top: 0
}

.product-wishlist {
    position: absolute;
    top: 20px;
    left: 30px;
    z-index: 9
}

.product-wishlist a {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 2px solid var(--bs-gray-300);
    line-height: 36px;
    border-radius: 50%;
    text-align: center;
    color: #adb5bd
}

.modal-button {
    position: absolute;
    right: 0;
    z-index: 2
}

@media (max-width:576px) {
    .modal-button {
        position: inherit
    }
}

[dir=rtl] .modal-button {
    left: 0;
    right: auto
}

.product-content {
    position: relative;
    -webkit-transition: all .2s;
    transition: all .2s
}

.product-detail .swiper-button-next,
.product-detail .swiper-button-prev {
    color: #5466f9;
    width: 35px;
    height: 35px;
    background: rgba(119, 106, 207, .3);
    border-radius: 50%
}

.product-detail .swiper-button-next:after,
.product-detail .swiper-button-prev:after {
    font-size: 20px
}

.product-detail .swiper-button-next {
    right: 20px;
    left: auto
}

.product-detail .swiper-button-prev {
    left: 20px;
    right: auto
}

.product-detail .swiper-slide-thumb-active {
    background-color: var(--bs-gray-300)
}

.product-detail .swiper-slide {
    padding: 5px 15px;
    border-radius: 5px
}

.product-desc-list li {
    padding: 2px 0
}

.product-desc-color a {
    display: inline-block;
    text-align: center;
    color: #1a1823;
    margin: 4px;
    border: 2px solid var(--bs-border-color);
    border-radius: 4px
}

.product-desc-color a.active,
.product-desc-color a:hover {
    color: #5466f9;
    border-color: #5466f9 !important
}

.product-review-link .list-inline-item a {
    color: #959396;
    padding: 4px 8px;
    font-size: 15px
}

.product-cart-touchspin {
    border: 1px solid var(--bs-input-border);
    background-color: var(--bs-input-bg);
    border-radius: .25rem
}

.product-cart-touchspin .form-control {
    border-color: transparent;
    height: 32px
}

.product-cart-touchspin .input-group-btn .btn {
    background-color: transparent !important;
    border-color: transparent !important;
    color: #5466f9 !important;
    font-size: 16px;
    padding: 3px 12px;
    -webkit-box-shadow: none;
    box-shadow: none
}

.shipping-address {
    -webkit-box-shadow: none;
    box-shadow: none
}

.shipping-address.active {
    border-color: #5466f9 !important
}

.product-tag {
    position: absolute;
    top: 0;
    z-index: 9;
    right: 0;
    width: 100%;
    display: block;
    font-size: 15px;
    padding: 0;
    overflow: hidden;
    height: 100px
}

.product-tag .badge {
    float: right;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    right: -67px;
    top: 17px;
    position: relative;
    text-align: center;
    width: 200px;
    font-size: 13px;
    margin: 0;
    padding: 7px 10px;
    font-weight: 500;
    color: #5466f9;
    background: #fff
}

.categories-filter .list-inline-item {
    position: relative;
    margin-right: 0
}

.categories-filter .list-inline-item a {
    display: block;
    color: var(--bs-body-color);
    background-color: rgba(119, 106, 207, .2);
    padding: 8px 15px;
    margin: 7px;
    cursor: pointer;
    border-radius: 4px
}

.categories-filter .list-inline-item a.active {
    color: #fff;
    background-color: #5466f9
}

.categories-filter .list-inline-item:last-child:after {
    display: none
}

.gallery-box {
    position: relative;
    text-align: center;
    overflow: hidden;
    border: 1px solid var(--bs-border-color)
}

.gallery-box .gallery-container {
    position: relative;
    overflow: hidden;
    border-radius: 4px 4px 0 0
}

.gallery-box .gallery-container a {
    display: block
}

.gallery-box .gallery-container .gallery-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    background: rgba(119, 106, 207, .2);
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: all .4s ease-in-out 0s;
    transition: all .4s ease-in-out 0s
}

.gallery-box .gallery-container .gallery-overlay .overlay-caption {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0
}

.gallery-box .gallery-img {
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.gallery-box .box-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -28px;
    background-color: var(--bs-card-bg);
    -webkit-transition: all .2s;
    transition: all .2s
}

.gallery-box .box-content .title {
    font-size: 14px;
    margin-bottom: 4px
}

.gallery-box .box-content .post {
    margin: 0;
    opacity: 0;
    -webkit-transition: all .2s;
    transition: all .2s;
    color: var(--bs-text-muted)
}

.gallery-box:hover .box-content {
    bottom: 0
}

.gallery-box:hover .box-content .post {
    opacity: 1
}

.gallery-box:hover .gallery-img {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px)
}

.gallery-box:hover .gallery-overlay {
    opacity: 1;
    visibility: visible
}

.user-profile-img {
    position: relative
}

.user-profile-img .overlay-content {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(119, 106, 207, .8);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    color: rgba(255, 255, 255, .6);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.user-profile-img .profile-img {
    width: 100%;
    height: 250px;
    -o-object-fit: cover;
    object-fit: cover
}

@media (max-width:991px) {
    .user-profile-img .profile-img {
        height: 160px
    }
}

.error-title {
    font-size: 130px
}

@media (max-width:425px) {
    .error-title {
        font-size: 100px
    }
}

.error-title .blink-infinite {
    -webkit-animation: infinite-blink 2s infinite;
    animation: infinite-blink 2s infinite
}

@-webkit-keyframes infinite-blink {
    60%,
    80% {
        color: #5466f9
    }
    100%,
    70% {
        color: #121416
    }
}

@keyframes infinite-blink {
    60%,
    80% {
        color: #5466f9
    }
    100%,
    70% {
        color: #121416
    }
}

.error-subtitle {
    font-weight: 400;
    background-color: #f5f5f8;
    max-width: 600px;
    font-size: 28px;
    letter-spacing: 2px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    white-space: nowrap
}

@media (max-width:425px) {
    .error-subtitle {
        font-size: 20px
    }
}

.faq-icon i {
    font-size: 80px;
    position: absolute;
    margin-top: -30px;
    right: -20px;
    opacity: .2
}

.pricing-box {
    border-top: 2px solid #5466f9
}

.pricing-box .pricing-features {
    color: var(--bs-text-muted)
}

.pricing-box .pricing-features li {
    padding: 4px 0
}

.error-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 130px
}

@media (max-width:1199.98px) {
    .error-text {
        font-size: 72px
    }
    .error-text .avatar-lg {
        width: 48px
    }
}

/*# sourceMappingURL=app.min.css.map */